//heights
export const appBar = 60;
export const envBar = 40;
export const footer = 30;

//widths
export const drawer = 250;

//menu icon buttons
export const MenuButtonIconProps = {
    width: 30,
    height: 30,
    m: 1 / 2,
}


export const MenuButtonProps = {
    color: '#FFFFFF',
    "& .Mui-disabled":
        {
            color: '#000000 !important'
        }
}
