import {AxiosResponse} from "axios";
import {Alert, AlertColor, Box, Typography} from "@mui/material";
import React from "react";

function CRUDAlert({object, severity='error'}:{object: AxiosResponse, severity?: AlertColor}) {
    // TODO: Improve UI error handling #6
    if (severity === 'error') {
        console.error(object)
    } else {
        console.log(object)
    }
    return <Alert severity={severity}>
        <Box sx={{minWidth: 160, width: 1, display: 'flex', flexDirection: 'column', p: 1,}}>
            <Box sx={{display: 'flex'}}>
                <Typography sx={{fontWeight: 'bold'}}>{'Status:'}</Typography>&nbsp;&nbsp;
                <Typography>{object?.status}</Typography>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Typography sx={{fontWeight: 'bold'}}>{'Message:'}</Typography>&nbsp;&nbsp;
                <Typography>{object?.statusText}</Typography>
            </Box>
            <Box sx={{display: 'flex'}}>
                <Typography sx={{fontWeight: 'bold'}}>{'Data:'}</Typography>&nbsp;&nbsp;
                <Typography sx={{whiteSpace: 'normal', wordBreak: 'break-word'}}>{JSON.stringify(object?.data)}</Typography>
            </Box>
        </Box>
    </Alert>
}

export default CRUDAlert
