import {useMutation, useQueryClient} from "@tanstack/react-query";
import {
    collection,
    deleteDoc,
    doc,
    FirestoreError,
    getFirestore,
    serverTimestamp,
    setDoc,
    updateDoc
} from "firebase/firestore";
import {app} from "../../auth/FirebaseConfig";
import {useAuthedUserContext} from "../../containers/auth/AuthedRoute";

export function useAddEvent(group_id: string | undefined) {

    const queryClient = useQueryClient()
    const user = useAuthedUserContext()

    return useMutation<any, FirestoreError, any, () => void>({
        mutationFn: async (newEvent) => {
            const db = getFirestore(app);
            const newRef = doc(collection(db, "events", group_id!, 'event'));
            await setDoc(newRef, {
                ...newEvent, _id: newRef.id, userId: [user!.uid], createdAt: serverTimestamp(), groupId: group_id
            })
            return newRef.id
        },
        onSuccess: async () => await queryClient.invalidateQueries({queryKey: ['events', group_id!, 'event']}),
        // onError: error => {
        //     console.log(error)
        //     // FIXME:
        // }
    })
}

export function useUpdateEvent(group_id: string) {

    const db = getFirestore(app)
    const queryClient = useQueryClient()

    return useMutation<any, FirestoreError, any, () => void>({
        mutationFn: async (event: any) => {
            const eventsRef = doc(db, "events", group_id, 'event', event._id)
            await updateDoc(eventsRef, {
                    ...event,
                    updatedAt: serverTimestamp()
                }
            )
            return {...event}
        },
        onSuccess: event => queryClient.invalidateQueries({queryKey: ['events', group_id!, 'event', event._id]}),
    })
}

export function useDeleteEvent(group_id: string) {

    const db = getFirestore(app)
    const queryClient = useQueryClient()

    return useMutation<any, FirestoreError, any, () => void>({
        mutationFn: async (id: any) => {
            return deleteDoc(doc(db, "events", group_id!, 'event', id))
        },
        // onError: error => {
        //     console.error(error)
        //     //     FIXME
        // },
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['events', group_id!, 'event']}),
    })
}