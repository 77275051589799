import {CalendarInterface} from "../../firestore_interfaces/CalendarInterface";
import {EventInterface} from "../../firestore_interfaces/EventInterface";
import {CategoryInterface} from "../../firestore_interfaces/CategoryInterface";
import googleFuncClient from "./GoogleFuncClient";

export interface PDFconfigInterface {
    calendar: CalendarInterface
    events: EventInterface[]
    period: {
        year: number
        month: number
    }
    groups: CategoryInterface[]
}

async function getPdf(pdf_config: PDFconfigInterface) {
    return await googleFuncClient().post<any>(`/generate-calendar-pdf-pub`, pdf_config, {
        responseType: 'blob'
    })
        .then((response: any) => response.data)
        .catch(async (error: any) => {
            console.error(error)
            let responseObj = await error.response.data.text();
            return Promise.reject({...error.response, data: responseObj})
        })
}

const CalendarService = {
    getPdf
}

export default CalendarService
