import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Clear, Save} from "@mui/icons-material";
import {getLocaleLanguage, languages, locale, localeCode} from "./Locales";
import {CalendarInterface} from "../../../firestore_interfaces/CalendarInterface";
import {useGetCalendar, useUpdateCalendar} from "../../../api/hooks/dbCalendar";


function CalendarConfig() {

    const {calendar_id} = useParams();
    const [localCalendar, setLocalCalendar] = useState<Partial<CalendarInterface>>({})

    const {mutate: updateCalendar} = useUpdateCalendar(calendar_id)
    const {data: calendar} = useGetCalendar(calendar_id, {
        languagePrimary: 'en_AU' as localeCode,
        firstDay: 0,
        showIcons: true,
        showAge: true
    })

    const mm_px_factor = 1.5
    const dims = {width: 297, height: 210}
    const weekdayOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const fontsizeOptions = [...Array(15).keys()].slice(7)

    const localeOptions = useMemo(() => {
        let tempLocales: locale[] = []
        Object.values(languages).forEach(lang => {
            Object.values(lang.locales).forEach(loc => {
                    tempLocales.push(loc)
                }
            )
        })
        return tempLocales
    }, [])

    const localeOptionToItem = (l: locale) => <MenuItem key={l.code} value={l.code}>{l.name}</MenuItem>

    const changesToSave = useMemo(() => {
        const changedVals: any = {}
        Object.entries(localCalendar).forEach(([key, val]) => {
            if (!['createdAt', 'updatedAt'].includes(key) && val !== calendar?.[key]) {
                changedVals[key] = val
            }
        })
        return changedVals
    }, [calendar, localCalendar])

    return (
        <Box sx={{width: 1, maxWidth: 'lg'}}>
            <Typography
                variant={'h1'}
                textAlign={'center'}
                sx={{mb: 2}}
            >
                Configure Calendar
            </Typography>
            <Box sx={{width: 1, display: 'flex', mb: 2}}>
                <Paper sx={{p: 2, display: 'flex', flexDirection: 'column', width: 1}}
                       variant={'outlined'}>
                    <Typography
                        variant={'h6'}
                        sx={{mb: 1}}
                    >
                        Calendar Name*
                    </Typography>
                    <TextField
                        fullWidth
                        // label={'Calendar Name'}
                        value={localCalendar?.name ?? calendar?.name ?? ''}
                        required
                        onChange={e => {
                            setLocalCalendar(c => {
                                return {...c, name: e.target.value}
                            })
                        }}
                        onBlur={e => {
                            if (e.target.value.length > 0) {
                                updateCalendar({name: e.target.value})
                                // TODO onSucess: Batch updateCalendarNameInUser
                            } else {
                                setLocalCalendar(c => {
                                    return {...c, name: calendar?.name}
                                })
                            }
                        }}
                        error={!!localCalendar?.name && localCalendar?.name.length === 0}
                        helperText={(!!localCalendar?.name && localCalendar?.name.length === 0) ? 'Name must contain a value' : ''}
                    />
                </Paper>
                {/*<Paper sx={{p: 1, display: 'flex', flexDirection: 'column', width: 1}} variant={'outlined'}>*/}
                {/*    <Typography*/}
                {/*        variant={'h6'}*/}
                {/*        sx={{m: 1,}}*/}
                {/*    >*/}
                {/*        Privacy & Sharing*/}
                {/*    </Typography>*/}
                {/*    <Box sx={{display: 'flex', flexDirection: 'column', px: 1}}>*/}
                {/*        <Autocomplete*/}
                {/*            fullWidth*/}
                {/*            disabled*/}
                {/*            multiple*/}
                {/*            value={[user?.email] || ''}*/}
                {/*            sx={{mb: 1}}*/}
                {/*            // onChange={(categories, newValue) => {*/}
                {/*            //     setLocalCalendar([*/}
                {/*            //         ...fixedOptions,*/}
                {/*            //         ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),*/}
                {/*            //     ]);*/}
                {/*            // }}*/}
                {/*            options={[]}*/}
                {/*            // renderTags={(tagValue, getTagProps) =>*/}
                {/*            //     tagValue.map((option, index) => (*/}
                {/*            //         <Chip*/}
                {/*            //             label={option.title}*/}
                {/*            //             {...getTagProps({ index })}*/}
                {/*            //             disabled={fixedOptions.indexOf(option) !== -1}*/}
                {/*            //         />*/}
                {/*            //     ))*/}
                {/*            // }*/}
                {/*            renderInput={(params) => (*/}
                {/*                <TextField {...params} label="Users" placeholder="someone@email.com"/>*/}
                {/*            )}*/}
                {/*        />*/}
                {/*        <Button variant={'outlined'} disabled>Invite another user</Button>*/}
                {/*        <FormHelperText>A calendar can be shared with up to 3 users</FormHelperText>*/}
                {/*    </Box>*/}
                {/*</Paper>*/}
            </Box>
            <Paper sx={{mb: 2, p: 1, display: 'flex', flexDirection: 'column'}} variant={'outlined'}>
                <Typography
                    variant={'h6'}
                    sx={{mx: 1, mt: 1, mb: -1}}
                >
                    Page Setup
                </Typography>
                <Box sx={{p: 1, display: 'flex'}}>
                    <Box sx={{width: 1, display: 'flex', flexDirection: 'column', mr: 2, flex: 5}}>
                        <Box sx={{flex: 1}}>
                            <FormControlLabel
                                control={<Checkbox
                                    sx={{py: 1 / 2}}
                                    checked={localCalendar?.showYear ?? calendar?.showYear ?? false}
                                    onChange={e =>
                                        setLocalCalendar(c => {
                                            return {...c, showYear: e.target.checked}
                                        })
                                    }
                                />}
                                label="Include Year"
                            />
                            <FormHelperText>Include the year, after the month name</FormHelperText>
                        </Box>
                        <Typography
                            variant={'subtitle2'}
                            sx={{mt: 1, mb: 1 / 3}}
                        >
                            Week starts on...
                        </Typography>
                        <FormControl
                            fullWidth
                        >
                            <Select
                                value={localCalendar?.firstDay ?? calendar?.firstDay ?? 0}
                                onChange={e =>
                                    setLocalCalendar(c => {
                                        return {...c, firstDay: e.target.value as number}
                                    })
                                }
                            >
                                {weekdayOptions.map((d, i) => <MenuItem key={i} value={i}>{d}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Typography
                            variant={'subtitle2'}
                            sx={{mt: 1, mb: 1 / 3}}
                        >
                            Header
                        </Typography>
                        <TextField
                            label={'Text'}
                            value={localCalendar?.header ?? calendar?.header ?? ''}
                            onChange={e =>
                                setLocalCalendar(c => {
                                    return {...c, header: e.target.value}
                                })
                            }
                        />
                        <Typography
                            variant={'subtitle2'}
                            sx={{mt: 1, mb: 1 / 3}}
                        >
                            Footer One
                        </Typography>
                        <Box sx={{display: 'flex'}}>
                            <TextField
                                label={'Text'}
                                value={localCalendar?.footerOneText ?? calendar?.footerOneText ?? ''}
                                onChange={e =>
                                    setLocalCalendar(c => {
                                        return {...c, footerOneText: e.target.value}
                                    })
                                }
                                fullWidth
                                sx={{flex: 2, mr: 1}}
                            />
                            <FormControl
                                fullWidth
                                sx={{flex: 1}}
                            >
                                <InputLabel>Font Size</InputLabel>
                                <Select
                                    value={localCalendar?.footerOneSize ?? calendar?.footerOneSize ?? ''}
                                    label="Font Size"
                                    onChange={e =>
                                        setLocalCalendar(c => {
                                            return {...c, footerOneSize: e.target.value as number}
                                        })
                                    }
                                >
                                    {fontsizeOptions.map(i => <MenuItem key={i} value={i}>{`${i} pt`}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                        <Typography
                            variant={'subtitle2'}
                            sx={{mt: 1, mb: 1 / 3}}
                        >
                            Footer Two
                        </Typography>
                        <Box sx={{display: 'flex'}}>
                            <TextField
                                fullWidth
                                sx={{flex: 2, mr: 1}}
                                label={'Text'}
                                value={localCalendar?.footerTwoText ?? calendar?.footerTwoText ?? ''}
                                onChange={e =>
                                    setLocalCalendar(c => {
                                        return {...c, footerTwoText: e.target.value}
                                    })
                                }
                            />
                            <FormControl
                                fullWidth
                                sx={{flex: 1}}
                            >
                                <InputLabel>Font Size</InputLabel>
                                <Select
                                    value={localCalendar?.footerTwoSize ?? calendar?.footerTwoSize ?? ''}
                                    label="Font Size"
                                    onChange={e =>
                                        setLocalCalendar(c => {
                                            return {...c, footerTwoSize: e.target.value as number}
                                        })
                                    }
                                >
                                    {fontsizeOptions.map(i => <MenuItem key={i} value={i}>{`${i} pt`}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box
                        sx={{width: 1, flex: 1}}
                    >
                        <Box sx={{px: 1}}>
                            <Box
                                sx={{
                                    bgcolor: 'grey.200',
                                    width: dims.width * mm_px_factor,
                                    height: dims.height * mm_px_factor
                                }}
                            >
                                Testing
                            </Box>
                            {/*{pdfUrl &&*/}
                            {/*    <iframe*/}
                            {/*        title={'CalendarPDF'}*/}
                            {/*        src={pdfUrl + '#toolbar=0&view=Fit'}*/}
                            {/*        width={}*/}
                            {/*        height={}*/}
                            {/*    />}*/}
                        </Box>
                        <Box>
                            <Typography
                                variant={'subtitle2'}
                                sx={{mt: 1, mb: 1}}
                            >
                                Language / Locale
                            </Typography>
                            <Box sx={{display: 'flex', mb: 1}}>
                                <FormControl
                                    fullWidth
                                    sx={{flex: 1, mr: 1}}
                                >
                                    <InputLabel>Primary</InputLabel>
                                    <Select
                                        value={localCalendar?.languagePrimary ?? calendar?.languagePrimary ?? ''}
                                        label="Primary"
                                        onChange={e =>
                                            setLocalCalendar(c => {
                                                return {...c, languagePrimary: e.target.value as localeCode}
                                            })
                                        }
                                    >
                                        {localeOptions.map(l => localeOptionToItem(l))}
                                    </Select>
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    sx={{flex: 1}}
                                >
                                    <InputLabel>Secondary</InputLabel>
                                    <Select
                                        endAdornment={
                                            <IconButton
                                                onClick={() => setLocalCalendar(c => {
                                                    return {...c, languageSecondary: null}
                                                })}
                                                sx={{mr: 1}}
                                            >
                                                <Clear/>
                                            </IconButton>}
                                        value={localCalendar?.languageSecondary ?? calendar?.languageSecondary ?? ''}
                                        label="Secondary"
                                        onChange={e =>
                                            setLocalCalendar(c => {
                                                return {...c, languageSecondary: e.target.value as localeCode}
                                            })
                                        }
                                    >
                                        {localeOptions
                                            .filter(l => getLocaleLanguage(l.code) !== getLocaleLanguage(localCalendar?.languagePrimary ?? calendar?.languagePrimary))
                                            .map(l => localeOptionToItem(l))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Alert severity={'info'}>
                                <AlertTitle>If you want events to be displayed in the above language(s), the
                                    relevant language <strong>'title'</strong> fields must be entered for the
                                    event.</AlertTitle>

                            </Alert>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{p: 1, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        variant={'contained'}
                        startIcon={<Save/>}
                        color={'success'}
                        disabled={Object.keys(changesToSave).length < 1}
                        onClick={() => updateCalendar(localCalendar)}
                    >
                        Save
                    </Button>
                </Box>
            </Paper>
            <Paper sx={{mb: 2, p: 1}} variant={'outlined'}>
                <Typography
                    variant={'h6'}
                    sx={{m: 1,}}
                >
                    Day Cell
                </Typography>
                <Box sx={{p: 1, display: 'flex'}}>
                    <Box sx={{width: 1, display: 'flex', flexDirection: 'column', mr: 1}}>
                        <FormControlLabel
                            control={<Checkbox
                                sx={{py: 1 / 2}}
                                checked={localCalendar?.crossPassed ?? calendar?.crossPassed ?? false}
                                onChange={e =>
                                    setLocalCalendar(c => {
                                        return {...c, crossPassed: e.target.checked}
                                    })
                                }
                            />}
                            label="Cross 'Past'"
                        />
                        <FormHelperText>Cross out days in past</FormHelperText>
                        <FormControlLabel
                            control={<Checkbox
                                sx={{py: 1 / 2}}
                                checked={localCalendar?.showJulian ?? calendar?.showJulian ?? false}
                                onChange={e =>
                                    setLocalCalendar(c => {
                                        return {...c, showJulian: e.target.checked}
                                    })
                                }
                            />}
                            label="Show Julian"

                        />
                        <FormHelperText>Display a secondary date of the Julian Calendar </FormHelperText>
                        <FormControlLabel
                            control={<Checkbox
                                sx={{py: 1 / 2}}
                                checked={localCalendar?.showAge ?? calendar?.showAge ?? true}
                                onChange={e =>
                                    setLocalCalendar(c => {
                                        return {...c, showAge: e.target.checked}
                                    })
                                }
                            />}
                            label="Show Age"
                        />
                        <FormHelperText>Display the Age/Yrs. Since Est. on events</FormHelperText>
                        <FormControlLabel
                            control={<Checkbox
                                sx={{py: 1 / 2}}
                                checked={localCalendar?.showIcons ?? calendar?.showIcons ?? true}
                                onChange={e => {
                                    setLocalCalendar(c => {
                                        return {...c, showIcons: e.target.checked}
                                    })
                                }
                                }
                            />}
                            label="Show Icons"
                        />
                        <FormHelperText>Disabling this will prevent icons from being shown beside labels (i.e. <i>Icon +
                            Label</i> type events)</FormHelperText>
                        <FormHelperText>Icon only events will still be included if selected</FormHelperText>
                    </Box>
                    <Box
                        sx={{width: 1}}
                    >
                    </Box>
                </Box>
                <Box sx={{p: 1, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        variant={'contained'}
                        startIcon={<Save/>}
                        color={'success'}
                        disabled={Object.keys(changesToSave).length < 1}
                        onClick={() => updateCalendar(localCalendar)}
                    >
                        Save
                    </Button>
                </Box>
            </Paper>
        </Box>
    )
}

export default CalendarConfig