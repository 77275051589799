import {Box, Paper, Typography} from "@mui/material";
import {MonthCalendar, YearCalendar} from "@mui/x-date-pickers";
import {getYear} from "date-fns";

function SelectMonth({selected, setSelected}: {selected: any, setSelected: any}) {

    console.log(selected)
    return (
        <Box sx={{width: 1, mb: 2, display: 'flex'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2, width: 1}}>
                <Typography
                    variant={'h5'}
                    sx={{mb: 1}}
                >
                    Year
                </Typography>
                <Paper sx={{width: 1, p: 1}} variant={'outlined'}>
                        <YearCalendar
                            sx={{width: 1}}
                            minDate={new Date(getYear(new Date()) - 1, 0, 1)}
                            maxDate={new Date(getYear(new Date()) + 10, 0, 1)}
                            value={new Date(selected?.year, 0,1) || null}
                            onChange={value => setSelected((s: any) => {return {...s, year: value.getFullYear()}})}
                        />
                </Paper>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2, width: 1}}>
                <Typography
                    variant={'h5'}
                    sx={{mb: 1}}
                >
                    Month
                </Typography>
                <Paper sx={{width: 1, p: 1}} variant={'outlined'}>
                        <MonthCalendar

                            sx={{width: 1}}
                            value={new Date(new Date().getFullYear(), selected?.month - 1,1) || null}
                            onChange={value => setSelected((s: any) => {return {...s, month: value.getMonth() + 1}})}

                        />
                </Paper>
            </Box>
        </Box>
    )
}

export default SelectMonth