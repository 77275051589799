import {TextField, Theme, useTheme, lighten} from "@mui/material";

export const disabledBlackOverride = (theme: Theme, props: any = {}) => {
    return {
        ...props.sx,
        "& .Mui-disabled":
            {
                "WebkitTextFillColor": lighten(theme.palette.text.primary, 0.3) + '!important', // (default alpha is 0.62)
            }

    }
}
export const LockedTextField = (props: any) => {
    const theme = useTheme()
    return (
        <TextField
            {...props}
            sx={disabledBlackOverride(theme, props)}
        />)
}
