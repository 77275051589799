import {invalidDay, invalidEst, invalidMonth} from "./AddEventCalcs";
import {TextField} from "@mui/material";
import React from "react";
import {GridRenderEditCellParams, useGridApiContext} from "@mui/x-data-grid";
import {DatePicker} from "@mui/x-date-pickers";

export function LabelEdit(props: GridRenderEditCellParams) {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();
    return <TextField
        value={value || ''}
        required
        fullWidth
        error={value.length < 1}
        onChange={e => {
            apiRef.current.setEditCellValue({
                id,
                field,
                value: e.target.value
            })
        }}
    />
}

export function DayEdit(props: GridRenderEditCellParams) {
    const {id, value, field, row} = props;
    const apiRef = useGridApiContext();
    return <TextField
        value={value || ''}
        required
        fullWidth
        type={'number'}
        placeholder={'DD'}
        error={!!invalidDay(row)}
        onChange={e => {
            apiRef.current.setEditCellValue({
                id,
                field,
                value: parseInt(e.target.value)
            })
        }}
    />
}

export function MonthEdit(props: GridRenderEditCellParams) {
    const {id, value, field, row} = props;
    const apiRef = useGridApiContext();
    return <TextField
        value={value || ''}
        required
        fullWidth
        type={'number'}
        placeholder={'MM'}
        error={!!invalidMonth(row)}
        onChange={e => {
            apiRef.current.setEditCellValue({
                id,
                field,
                value: parseInt(e.target.value)
            })
        }}
    />
}

export function EstEdit(props: GridRenderEditCellParams) {
    const {id, value, field, row} = props;
    const apiRef = useGridApiContext();
    return <TextField
        value={value || ''}
        sx={{}}
        required
        fullWidth
        type={'number'}
        placeholder={'YYYY'}
        error={!!invalidEst(row)}
        onChange={e => {
            apiRef.current.setEditCellValue({
                id,
                field,
                value: parseInt(e.target.value)
            })
        }}
    />
}

export function CustomDatePicker(props: GridRenderEditCellParams) {
    const {id, value, field} = props;
    const apiRef = useGridApiContext();

    return <DatePicker
        value={value ? value : null}
        format={'dd/MM/yyyy'}
        onChange={(newVal) => {
            apiRef.current.setEditCellValue({
                id,
                field,
                value: newVal
            })
        }}
        sx={{width: 1}}
    />
}