import {useMutation} from "@tanstack/react-query";
import {
    collection, deleteDoc,
    doc,
    FirestoreError,
    getDoc,
    getFirestore,
    serverTimestamp,
    setDoc,
    updateDoc
} from "firebase/firestore";
import {app} from "../../auth/FirebaseConfig";
import {firebaseCleanse} from "../functions/FirbaseCleanse";
import {useQuery} from "@tanstack/react-query";
import {useQueryClient} from "@tanstack/react-query";

export function useAddEventsGroup(uid: string | undefined){
    return useMutation<any, FirestoreError, any, () => void>({
        mutationFn: async (newGroup) => {
            const db = getFirestore(app);
            const newCalRef = doc(collection(db, "events"));
            //TODO: remove undefined keys
            await setDoc(newCalRef, {
                ...firebaseCleanse(newGroup), _id: newCalRef.id, userId: [uid], createdAt: serverTimestamp()
            })
            return {...newGroup, _id: newCalRef.id}
        },
        // onError: error => {
        //     console.log(error)
        //     enqueueSnackbar(error.message, {variant: 'error'})
        // },
    })
}

export function useGetEventsGroup(group_id: string | undefined){
    const db = getFirestore(app);
    return useQuery<any, FirestoreError>({
        queryKey: ["events", group_id],
        queryFn: () => getDoc(doc(db, "events", group_id!)),
        enabled: !!group_id, //&& !deleteInProgress
        select: data => data?.data(),
        // onError: error => {
        // console.error(error)
        // enqueueSnackbar(error.message, {variant: 'error'})
        // }
    })
}

export function useUpdateEventGroup(group_id: string | undefined){
    const db = getFirestore(app);
    const queryClient = useQueryClient()
    return useMutation<any, FirestoreError, any, () => void>({
        mutationFn: async (eventGroup) => {
            const eventsRef = doc(db, "events", group_id!)
            await updateDoc(eventsRef, {
                    ...eventGroup,
                    updatedAt: serverTimestamp()
                }
            )
            return {...eventGroup, _id: eventsRef.id}
        },
        onError: error => {
            console.error(error)
            //TODO:  enqueueSnackbar(error.message, {variant: 'error'})
        },
        onSuccess: async () => queryClient.invalidateQueries({queryKey: ["events", group_id]})
    })
}

export function useDeleteEventGroup(group_id: string | undefined) {
    const db = getFirestore(app);
    const queryClient = useQueryClient()
    return useMutation<any, FirestoreError>({
        mutationFn: async () => {
            const ref = doc(db, "events", group_id!)
            await deleteDoc(ref)
            return ref.id
        },
        onSuccess: (id) => {
            //TODO: Add 'Delete' cloud function and update UI #1
            queryClient.invalidateQueries({queryKey: ["events", group_id, 'event']})
            // TODO: make transaction
            // removeGroupFromUser(id)
        },
        // onError: error => {
        //     console.error(error)
        //     enqueueSnackbar(error.message, {variant: 'error'})
        // },
    })
}