import React from "react";

import {appBar, MenuButtonIconProps, MenuButtonProps} from "../theming/StyleContstants";
import AppBar from '@mui/material/AppBar';
import {Box, IconButton, Toolbar, Tooltip, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import UserMenu from "../auth/UserMenu";
import {CalendarMonth, Event, Info} from "@mui/icons-material";
import packageJson from '../../package.json'

function Header() {

    let navigate = useNavigate();
    return (
        <AppBar
            sx={{
                height: appBar,
                position: 'fixed',
                py: 1 / 5,
                px: 2,
                justifyContent: 'center',
                display: 'flex'
            }}
        >
            <Toolbar
                sx={{display: 'flex', flexDirection: 'row', width: 1, px: '0px !important', justifyContent: 'center'}}>
                <Box sx={{display: {sm: 'none', xl: 'flex'}, flex: 1}}/>
                <Box sx={{
                    flex: 6,
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: 'lg'
                }}>
                    <Box sx={{display: "flex", flex: 1}}>
                        <Tooltip title={<Typography sx={{fontSize: 13}}>Events</Typography>}>
                            <IconButton
                                sx={{...MenuButtonProps}}
                                color="inherit"
                                aria-label="Menu"
                                onClick={() => {
                                    navigate('/events')
                                }}
                            >
                                <Event sx={MenuButtonIconProps}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={<Typography sx={{fontSize: 13}}>Calendars</Typography>}>
                            <IconButton
                                sx={{...MenuButtonProps}}
                                color="inherit"
                                aria-label="Menu"
                                onClick={() => {
                                    navigate('/calendar')
                                }}
                            >
                                <CalendarMonth sx={MenuButtonIconProps}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    >
                        <Typography variant="h2" style={{fontWeight: "bold", textAlign: 'center'}}>
                            {packageJson.officialName}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                        <Tooltip title={<Typography style={{fontSize: 13}}>About</Typography>}
                                 sx={{p: 1}}>
                            <IconButton
                                sx={{...MenuButtonProps}}
                                name="info"
                                color="inherit"
                                onClick={() => {
                                    navigate('/about')
                                }}
                            >
                                <Info sx={MenuButtonIconProps}/>
                            </IconButton>
                        </Tooltip>
                        <UserMenu/>
                    </Box>
                </Box>
                <Box sx={{display: {sm: 'none', xl: 'flex'}, flex: 1}}/>
            </Toolbar>
        </AppBar>
    );
}

export default Header
