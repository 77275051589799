import axios from "axios";

const googleAPIClient = (userToken?: string) => (() => {
    console.log('googleAPIClient.userToken', userToken)
    const headers: HeadersInit = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userToken !== undefined ? `Bearer ${userToken}` : ''
    }

    const params = userToken ? {} : {
        key: process.env.REACT_APP_GOOGLE_API_KEY
    }

    return axios.create({
        baseURL: "https://www.googleapis.com/calendar/v3",
        headers: headers,
        params: params
    })
})()

export default googleAPIClient
