import {Box, Button, Typography} from "@mui/material";
import {To, useNavigate} from "react-router-dom";
import Grid2 from '@mui/material/Unstable_Grid2'

import {collection, doc, FirestoreError, getFirestore, serverTimestamp, setDoc} from "firebase/firestore";
import {app} from "../../../auth/FirebaseConfig";
import {useAuthedUserContext} from "../../auth/AuthedRoute";
import {AddCircle, Event} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import {useGetUser, useUpdateUser} from "../../../api/hooks/dbUser";
import {useAddCalendar} from "../../../api/hooks/dbCalendar";


function CalendarSelect() {


    const navigate = useNavigate()
    const user: any = useAuthedUserContext()
    const {enqueueSnackbar} = useSnackbar();

    const {data: userData} = useGetUser(user?.uid)

    const calendars: { [key: string]: string } | undefined = userData?.calendars

    const {mutate: addCalendar} = useAddCalendar()

    const {mutate: updateUser} = useUpdateUser(user?.uid)

    return (
        <Box sx={{width: 1, maxWidth: 'lg'}}>
            <Typography
                variant={'h1'}
                textAlign={'center'}
            >
                Calendars
            </Typography>
            <Typography
                variant={'body1'}
                textAlign={'center'}
                sx={{mb: 2}}
            >
                Your saved calendar configurations
            </Typography>

            <Box sx={{width: 1, display: 'flex', justifyContent: 'center', maxWidth: 'lg'}}>
                <Grid2 container spacing={2} sx={{width: 1}}>
                    {calendars && Object.entries(calendars).map(([key, val]) => {
                        return <Grid2 xs={3} key={key}>
                            <Button
                                variant={'outlined'}
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: 1,

                                }}
                                onClick={() => navigate(key + '/configure')}
                            >
                                <Event sx={{m: 1}}/>
                                <Typography>
                                    {val}
                                </Typography>
                            </Button>
                        </Grid2>
                    })}
                    <Grid2 xs={3} key={'add'}>
                        <Button
                            variant={'outlined'}
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: 1,

                            }}
                            onClick={() => addCalendar({name: 'My New Calendar'}, {
                                onSuccess: (calendar: any) => {
                                    navigate(calendar._id)
                                }}
                            )}
                        >
                            <AddCircle sx={{m: 1}}/>
                            <Typography>
                                Start a new Calendar
                            </Typography>
                        </Button>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    )
}

export default CalendarSelect