import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Paper,
    Tooltip,
    Typography
} from "@mui/material";
import {useAuthedUserContext} from "../auth/AuthedRoute";
import React, {useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Add} from "@mui/icons-material";
import AddEventGroupModal from "../path/categories/AddEventGroupModal";
import {useGetUser} from "../../api/hooks/dbUser";

function EventGroups() {
    const navigate = useNavigate()
    const location = useLocation()
    const [openAdd, setOpenAdd] = useState(false)

    const user: any = useAuthedUserContext()
    const userId = user?.uid
    const {data: userData} = useGetUser(userId)

    const events: { [key: string]: string } | undefined = userData?.events

    return <Box sx={{width: 1, maxWidth: 'lg'}}>
        <Typography
            variant={'h1'}
            textAlign={'center'}
            sx={{mb: 2}}
        >
            Events
        </Typography>
        <Box sx={{width: 1, display: 'flex', maxWidth: 'lg', flexDirection: {sm: 'column', lg: 'row'}}}>
            <Paper sx={{mr: {sm: 0, lg: 2}, minWidth: 300, mb: 2, flex: 1}} variant={'outlined'}>
                <List
                    sx={{
                        width: 1,
                        maxHeight: {sm: 300, lg: 1000},
                        position: 'relative',
                        overflow: 'auto',
                        py: 0,
                    }}
                >
                    <>
                        <ListSubheader>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: 1}}>
                                My Event Groups
                                <Tooltip title={'Add a new group of events'}>
                                    <IconButton
                                        onClick={() => setOpenAdd(true)}
                                    >
                                        <Add/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Divider/>
                        </ListSubheader>
                    </>
                    {events && Object.entries(events).map(([key, val]) => <ListItem disablePadding key={key}>
                        <ListItemButton
                            selected={location.pathname.includes(key)}
                            onClick={() => navigate(key)}
                        >
                            <ListItemText primary={val}/>
                        </ListItemButton>
                    </ListItem>)}
                </List>
            </Paper>
            <Paper sx={{
                mb: 2,
                flex: 3,
                p: 2
            }} variant={'outlined'}>
                <Outlet/>
            </Paper>
            <AddEventGroupModal open={openAdd} setOpen={setOpenAdd}/>
        </Box>
    </Box>
}

export default EventGroups