import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormHelperText,
    Stack,
    TextField
} from "@mui/material";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {CategoryInterface} from "../../../firestore_interfaces/CategoryInterface";
import {EventInterface} from "../../../firestore_interfaces/EventInterface";
import {format, getDate, getMonth, getYear} from "date-fns";
import {DatePicker} from "@mui/x-date-pickers";
import {dateSingle} from "./EventProps";
import {FieldChangeHandlerContext} from "@mui/x-date-pickers/internals";
import {ageCalc, invalidDay, invalidEst, invalidMonth} from "./AddEventCalcs";
import {LoadingButton} from "@mui/lab";
import {languageCode} from "../calendar/Locales";
import {useAddEvent} from "../../../api/hooks/dbEvent";

function AddEventModal({open, setOpen, group: category}: { open: boolean, setOpen: any, group: CategoryInterface }) {

    const {events_id} = useParams();
    const {mutate: addEvent, error: addError, isPending: loadingAdd} = useAddEvent(events_id)

    const [newEvent, setNewEvent] = useState<Partial<EventInterface>>({})

    const invalidHelper = (context: Partial<FieldChangeHandlerContext<any>>, min?: Date, max?: Date) => {
        if (context?.validationError && min && max) {
            switch (context.validationError) {
                case 'minDate':
                    return `Please select a date after ${format(min, 'dd / MMM / yyyy')}`
                case 'maxDate':
                    return `Please select a date before ${format(max, 'dd / MMM / yyyy')}`
                default:
                    return 'This is an invalid date'
            }
        } else {
            const err = Object.values(context).filter((val) => val)
            return err.length > 0 ? err[0] : ''
        }
    }

    const [dateValid, setDateValid] = useState<Partial<FieldChangeHandlerContext<any> | any>>({})

    return <Dialog open={open}>
        <DialogTitle sx={{width: 400}}>Add new Event</DialogTitle>
        <DialogContent>
            {(category?.label && category?.languages) &&
                category.languages.map(lang => {
                    const l: languageCode = lang?.language ? lang.language : '' as languageCode
                    return <TextField
                        key={l}
                        sx={{my: 1 / 2}}
                        required
                        label={`Label (${l.toUpperCase()})`}
                        fullWidth
                        value={newEvent?.label?.[l] || ''}
                        onChange={e => {
                            setNewEvent(g => {
                                return {
                                    ...g,
                                    label: g?.label ? {
                                        ...g.label,
                                        [l]: e.target.value as string
                                    } : {[l]: e.target.value as string}
                                } as Partial<EventInterface>
                            })
                        }}
                    />
                })
            }
            {!category?.annual ?
                <>
                    <DatePicker
                        label={'Date*'}
                        sx={{mb: 1 / 3, mt: 1, width: 1}}
                        value={newEvent?.date || null}
                        format={'dd/MMM/yyyy'}
                        {...dateSingle}
                        onChange={(newValue, context) => {
                            setDateValid(context)
                            setNewEvent(e => {
                                if (newValue) return {
                                    ...e,
                                    date: newValue,
                                    day: getDate(newValue),
                                    month: getMonth(newValue) + 1,
                                    year: getYear(newValue)
                                }
                                return e
                            })
                        }}
                    />
                    {dateValid?.validationError &&
                        <FormHelperText>{invalidHelper(dateValid, new Date(), dateSingle.maxDate)}</FormHelperText>}
                </> : <>
                    <Box sx={{display: 'flex'}}>
                        <Stack
                            sx={{my: 1, flex: 5, mr: 1}}
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem sx={{transform: 'rotate(9deg)'}}/>}
                            spacing={1.5}
                        >
                            <TextField
                                sx={{flex: 1}}
                                label={'Day'}
                                placeholder={'DD'}
                                required
                                fullWidth
                                value={newEvent?.day || null}
                                type={'number'}
                                error={dateValid?.dayError}
                                onChange={e => {
                                    setNewEvent(n => {
                                        const newE = {...n, day: parseInt(e.target.value)}
                                        setDateValid(v => {
                                            return {...v, dayError: invalidDay(newE)}
                                        })
                                        return newE
                                    })
                                }}
                            />
                            <TextField
                                sx={{flex: 1}}
                                label={'Month'}
                                placeholder={'MM'}
                                required
                                fullWidth
                                value={newEvent?.month || null}
                                type={'number'}
                                error={dateValid?.monthError}
                                onChange={e => setNewEvent(n => {
                                    const newE = {...n, month: parseInt(e.target.value)}
                                    setDateValid(v => {
                                        return {...v, monthError: invalidMonth(newE), dayError: invalidDay(newE)}
                                    })
                                    return newE
                                })}
                            />
                            <TextField
                                sx={{flex: 1.5}}
                                label={'Year'}
                                placeholder={'YYYY'}
                                fullWidth
                                value={newEvent?.est || ''}
                                type={'number'}
                                error={dateValid?.estError}
                                onChange={e => setNewEvent(n => {
                                    const newE = {...n, est: parseInt(e.target.value)}
                                    setDateValid(v => {
                                        return {...v, estError: invalidEst(newE), dayError: invalidDay(newE)}
                                    })
                                    return newE
                                })}
                            />
                        </Stack>
                        <TextField
                            sx={{flex: 2, my: 1, ml: 1}}
                            label={'Age'}
                            value={ageCalc(newEvent)}
                            disabled
                        />
                    </Box>
                    {invalidHelper(dateValid) &&
                        <FormHelperText error><strong>Error:</strong> {invalidHelper(dateValid)}</FormHelperText>}
                    <FormHelperText><strong>Note:</strong> Year is only required to calculate the age /
                        anniversary / years since establishment (Recommended).
                    </FormHelperText>
                </>
            }
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    setOpen(false)
                    setNewEvent({})
                    setDateValid({})
                }}
                color={'error'}
            >
                Cancel
            </Button>
            <LoadingButton
                loading={loadingAdd}
                onClick={() => addEvent(undefined, {
                    onSuccess: () => {
                        setOpen(false)
                        setNewEvent({})
                        setDateValid({})
                    }
                })}
                color={'success'}
                disabled={
                    Object.keys(newEvent).length < 0 ||
                    !newEvent?.month ||
                    !newEvent?.day ||
                    dateValid?.validationError ||
                    invalidHelper(dateValid) ||
                    !!addError
                }
            >
                Add Event
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

export default AddEventModal