import {Box, CircularProgress} from "@mui/material";
import {useParams} from "react-router-dom";
import React from "react";
import EventsConfig from "./eventsConfig/EventsConfig";
import {useGetEventsGroup} from "../../../api/hooks/dbEventsGroup";


function Events() {
    const {events_id} = useParams();
    const {data: eventGroup, isLoading} = useGetEventsGroup(events_id)

    return <Box sx={{display: 'flex', justifyContent: 'center'}}>
        {isLoading ? <CircularProgress sx={{m: 3}}/> : <EventsConfig eventGroup={eventGroup}/>}
    </Box>
}

export default Events