import {GridToolbarContainer} from "@mui/x-data-grid";
import {Button} from "@mui/material";
import {Add, Inventory} from "@mui/icons-material";
import React from "react";

function EditToolbar({setOpenAdd}: { setOpenAdd: any }) {
    return (
        <GridToolbarContainer sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Button
                color="primary"
                startIcon={<Add/>}
                onClick={() => setOpenAdd(true)}
            >
                Add Event
            </Button>
            <Button
                color="primary"
                startIcon={<Inventory/>}
                disabled
                // TODO: onClick
                // onClick={() => setOpenAdd(true)}
            >
                View Past Events
            </Button>
        </GridToolbarContainer>
    );
}

export default EditToolbar