import {GAPICalendarEvent} from "../google_cal_interfaces/GAPICalendarEvent";
import {EventInterface} from "../firestore_interfaces/EventInterface";
import {isValid} from "date-fns";

export function googleConvert(event: GAPICalendarEvent): Partial<EventInterface> {
    let date
    if (event?.start?.date) {
        date = new Date(event?.start?.date)
    } else if (event?.start?.dateTime) {
        date = new Date(event?.start?.dateTime)
    }

    if (date && isValid(date)) {
        return {
            ...event,
            label: {
                en: event?.summary
            },
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            date: date
        }
    } else {
        //     FIXME: Error
        return {}
    }

}