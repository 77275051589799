import {Box, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Print, Settings} from "@mui/icons-material";
import {useEffect, useState} from "react";

function CalendarGenCon() {

    const location = useLocation()
    const navigate = useNavigate()
    const configure = location.pathname.endsWith('configure')
    const generate = location.pathname.endsWith('generate')
    const [page, setPage] = useState('configure')
    useEffect(() => {
        if (configure) setPage('configure')
        if (generate) setPage('generate')
    }, [configure, generate])

    if (!configure && !generate) {
        return <Navigate to={'configure'} replace/>
    }

    return (
        <Box sx={{width: 1, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            {/*<ToggleButtonGroup*/}
            {/*    color="primary"*/}
            {/*    value={alignment}*/}
            {/*    exclusive*/}
            {/*    onChange={handleChange}*/}
            {/*    aria-label="Platform"*/}
            {/*>*/}
            {/*    <ToggleButton value="web">Web</ToggleButton>*/}
            {/*    <ToggleButton value="android">Android</ToggleButton>*/}
            {/*    <ToggleButton value="ios">iOS</ToggleButton>*/}
            {/*</ToggleButtonGroup>*/}
            <ToggleButtonGroup
                exclusive
                value={page}
                onChange={(e, val) => setPage(val)}
                sx={{mb: 1}}
                color={'primary'}
            >
                <ToggleButton
                    value={'configure'}
                    disabled={configure}
                    onClick={() => navigate('./configure')}
                >
                    <Settings sx={{m: 1}}/>
                    <Typography>
                        Configure
                    </Typography>
                </ToggleButton>
                <ToggleButton
                    value={'generate'}
                    disabled={generate}
                    onClick={() => navigate('./generate')}
                >
                    <Print sx={{m: 1}}/>
                    <Typography>
                        Generate
                    </Typography>
                </ToggleButton>
            </ToggleButtonGroup>
            <Outlet/>
        </Box>
    )
}

export default CalendarGenCon