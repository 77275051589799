import React, {createContext, useContext, useEffect, useState} from 'react';
import {loadAuth2, loadGapiInsideDOM} from 'gapi-script';
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import GoogleService from "../api/services/GoogleCalendarAPI";
import {useGetAccessToken} from "./GetAccessToken";

interface GoogleAuthContextInterface {
    googleUser: any,
    signOut: any,
    signIn: any,
    gapi: any,
    timezone: string
}

export const GoogleAuthContext = createContext<GoogleAuthContextInterface>({} as GoogleAuthContextInterface);
export const useGoogleAuthContext = () => useContext(GoogleAuthContext)

function GoogleAuth({children}: { children: any }) {

    const queryClient = useQueryClient()
    const token = useGetAccessToken()

    const [user, setUser] = useState<any>(undefined);
    const [gapi, setGapi] = useState<any>(undefined);

    const scope = 'https://www.googleapis.com/auth/calendar.readonly'

    useEffect(() => {
        (async () => {
            const newGapi = await loadGapiInsideDOM();
            setGapi(newGapi);
        })()
    }, []);

    useEffect(() => {
        if (gapi) {
            loadAuth2(gapi, process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!, scope).then(auth2 => {
                if (auth2.isSignedIn.get() &&  auth2.currentUser.get()?.xc?.scope.includes(scope)) {
                    updateUser(auth2.currentUser.get())
                } else {
                    setUser(null)
                }
            })
        }
    }, [gapi]);

    const updateUser = (currentUser: any) => {
        const name = currentUser.getBasicProfile().getName();
        const profileImg = currentUser.getBasicProfile().getImageUrl();
        setUser({
            name: name,
            profileImg: profileImg,
        });
    };

    const signIn = async () => loadAuth2(gapi, process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID!, scope)
        .then(auth2 => auth2.signIn())

    const signOut = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut()
            .then(() => queryClient.invalidateQueries({queryKey: ['GoogleService']})
                .then(() => setUser(null)))
    }

    const {data: timezone} = useQuery<any, AxiosResponse>({
        queryKey: ["GoogleService", 'getCalendarList'],
        queryFn: () => GoogleService.getUserSetting(token, 'timezone'),
        enabled: !!token
    });

    return (
        <GoogleAuthContext.Provider value={{
            googleUser: user,
            signOut,
            signIn,
            gapi,
            timezone
        }}>
            {children}
        </GoogleAuthContext.Provider>
    )
}

export default GoogleAuth