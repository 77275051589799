import {initializeApp} from "firebase/app"

const firebaseConfig = {
    apiKey: "AIzaSyAXSxpls1YBD4X4HTCkSS99c8btpw6zvJk",
    authDomain: "mycal-367400.firebaseapp.com",
    projectId: "mycal-367400",
    storageBucket: "mycal-367400.appspot.com",
    messagingSenderId: "779315384823",
    appId: "1:779315384823:web:3206760d66523b52ac5ef2",
    measurementId: "G-B936TE2221",
    persistence: 'SESSION'
};

export const app = initializeApp(firebaseConfig);