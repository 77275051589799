import {Alert, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React from "react";
import {format, isValid} from "date-fns";
import {CalendarInterface} from "../../../../firestore_interfaces/CalendarInterface";
import {getLocaleLanguage} from "../Locales";
import {CategoryInterface} from "../../../../firestore_interfaces/CategoryInterface";
import {googleToFirebaseEvents} from "../../../../api/functions/GoogleToFirebase";
import {EventInterface} from "../../../../firestore_interfaces/EventInterface";
import {ageCalc} from "../../categories/AddEventCalcs";
import {LanguageAPI} from "../../../../firestore_interfaces/LanguageAPI";
import RenderGAPIError from "../../../GAPIError";
import {GAPIErrorInterface} from "../../../../google_cal_interfaces/GAPIErrorInterface";
import {useGetEventsGroup} from "../../../../api/hooks/dbEventsGroup";
import {useGetEvents} from "../../../../api/hooks/dbEventList";
import {useGoogleCalendarEvents} from "../../../../api/hooks/googleGet";

function EventsInGroup({
                           group_id,
                           period,
                           calendar
                       }: { group_id: string | undefined, period: any, calendar: CalendarInterface }) {

    // const {timezone} = useGoogleAuthContext()

    const primLang = getLocaleLanguage(calendar?.languagePrimary)
    const secLang = getLocaleLanguage(calendar?.languageSecondary)

    const {data: group} = useGetEventsGroup(group_id)
    const {data: events} = useGetEvents(group_id, period, group?.source)

    // TODO: timezone offset calc
    const googleCalIds: CategoryInterface[] = (group?.source === 'api' && group?.languages) ? group.languages.filter((l: LanguageAPI) => l?.language && [primLang?.code, secLang?.code].includes(l.language)).map((lang: LanguageAPI) => {
        return {groupId: group_id, google: lang?.google}
    }) : []
    const {
        data: googleEvents,
        pending: googleEventsPending,
        error: googleEventsError
    } = useGoogleCalendarEvents(googleCalIds, period, 100)

    // TODO: use googleEvents, googleEventsPending, googleEventsError
    let apiEventData: any[] = []
    // can't useEffect or useMemo as useQueries triggers on every render
    // apiEventQueries.filter((q: any) => !q.isError && !q.isLoading).forEach(q => {
    //     if (q?.data?.items) {
    //         apiEventData = apiEventData.concat(q.data.items)
    //     }
    // })

    console.log('apiEventData', apiEventData, googleCalIds)
    const data: EventInterface[] = group?.source === 'api' ? apiEventData.map(e => googleToFirebaseEvents(e)) : events

    return (
        <TableContainer sx={{maxHeight: '70vh'}}>
            <Table sx={{minWidth: 650}}>
                <TableHead>
                    <TableRow>
                        {primLang && <TableCell>{primLang.label}</TableCell>}
                        {secLang && <TableCell>{secLang.label}</TableCell>}
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">Est. / Age</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.sort((a, b) => a.day - b.day).map((e) => (
                        <TableRow
                            key={e._id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            {primLang && <TableCell component="th" scope="row">{e?.label?.[primLang.code]}</TableCell>}
                            {secLang && <TableCell component="th" scope="row">{e?.label?.[secLang.code]}</TableCell>}
                            <TableCell
                                align="center">{e?.date && isValid(e?.date) && format(new Date(e.date), 'do')}</TableCell>
                            <TableCell
                                align="center">{ageCalc(e)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {googleEventsPending && <LinearProgress/>}
            {googleEventsError && <Alert severity={'error'}>
                <RenderGAPIError error={googleEventsError[0]?.error?.data as GAPIErrorInterface}/></Alert>}
        </TableContainer>
    )
}

export default EventsInGroup