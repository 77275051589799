import {AuthError} from "firebase/auth";
import {Link} from "@mui/material";
import {useNavigate} from "react-router-dom";
import packageJson from '../../../package.json'

function RenderFirebaseAuthError({error}: {error: AuthError}){
    const navigate =useNavigate()
    switch(error.code){
        case 'auth/email-already-exists':
            return <><strong>Incorrect password.</strong> Please try again later, or you can <Link color={'inherit'} style={{ cursor: 'pointer' }} onClick={() => navigate('/loginHelp')} >reset your password</Link>.</>
        case 'auth/internal-error':
            // TODO
            return <></>
        case 'auth/too-many-requests':
            // TODO
            return <></>
        case 'auth/user-not-found':
            return <><strong>Account not found.</strong> Sorry, but we can't find an account with this email address. Please try again later, or <Link color={'inherit'} style={{ cursor: 'pointer' }} onClick={() => navigate('/signup/regform')} >create a new account</Link>.</>
        default:
            return <><strong>Authentication Error.</strong> Please try again, or <Link
                color={'inherit'}
                style={{ cursor: 'pointer' }}
                target="_top"
                rel="noopener noreferrer"
                href={`mailto:support@${packageJson.name}.com`}
            >send an email</Link> for technical assistance.</>
    }
}

export default RenderFirebaseAuthError