import axios from "axios";

const googleFuncClient = () =>{

    const headers: HeadersInit = {
        "Content-Type": "application/json",
        Accept: "application/json",
    }

    return axios.create({
        baseURL: process.env.REACT_APP_GOOGLE_FUNC_ADDRESS!,
        headers: headers
    })
}

export default googleFuncClient
