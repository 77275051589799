import {Link} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {GAPIErrorInterface} from "../google_cal_interfaces/GAPIErrorInterface";
import packageJson from '../../package.json'
import {useGoogleAuthContext} from "../auth/GoogleLogin";

function RenderGAPIError({error}: { error: GAPIErrorInterface }) {
    // TODO: Improve UI error handling #6
    const navigate = useNavigate()
    const {googleUser} = useGoogleAuthContext()
    console.log('loggedin', !!googleUser)
    // TODO: Return error code
    switch (error.error.code) {
        case 404:
            // TODO: return to correct calendar selection
            if (!!googleUser) {
                return <>Google is <strong>unable to find</strong> this calendar. Please return to <Link
                    color={'inherit'} style={{cursor: 'pointer'}} onClick={() => navigate('/events')}>event
                    configuration</Link> to confirm it has been entered correctly.</>
            } else {
                return <>Google is <strong>unable to find</strong> this calendar. If this calendar is <i>private</i>,
                    please <Link color={'inherit'} style={{cursor: 'pointer'}} onClick={() => navigate('/profile')}>login
                        to Google</Link>, otherwise you may return to <Link color={'inherit'}
                                                                            style={{cursor: 'pointer'}}
                                                                            onClick={() => navigate('/events')}>event
                        configuration</Link> to confirm it has been entered correctly.</>
            }
        case 401:
        case 403:
            return <>You are currently <strong>unauthenticated</strong>. Please return to <Link color={'inherit'}
                                                                                                style={{cursor: 'pointer'}}
                                                                                                onClick={() => navigate('/profile')}>sign
                into google</Link>.</>
        case 400:
        default:
            return <><strong>Google Error.</strong> Please try again, or <Link
                color={'inherit'}
                style={{cursor: 'pointer'}}
                target="_top"
                rel="noopener noreferrer"
                href={`mailto:support@${packageJson.name}.com`}
            >send an email</Link> for technical assistance.</>
    }
}

export default RenderGAPIError