import {Alert, Box, Checkbox, FormControlLabel, Step, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import {AuthError, createUserWithEmailAndPassword, getAuth, UserCredential} from "firebase/auth";
import {useLocation, useNavigate} from "react-router-dom";
import packageJson from '../../../package.json'
import {useFormik} from 'formik';
import * as yup from 'yup';
import {LoadingButton} from "@mui/lab";
import {useLoginUser} from "../../api/hooks/firebaseUser";

function PlanForm() {

    const location = useLocation()

    const {
        mutate: login,
        isPending,
        error
    } = useLoginUser()

    const RegisterSchema = yup.object({
        email: yup
            .string()
            .email('Please enter a valid email address.')
            .required('Email is required.'),
        password: yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length.')
            .required('Password is required.'),
        noOffers: yup.boolean().default(false)
    });

    const formik = useFormik({
        initialValues: {
            email: location.state.email as string,
            password: '',
            noOffers: false
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => login(values)
    });

    return (<Box sx={{display: 'flex', width: 1, justifyContent: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', width: 460, m: 10}}>

            <Stepper activeStep={0} sx={{mb: 3, mx: 1}}>
                {[1, 2, 3].map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}/>
                        </Step>
                    );
                })}
            </Stepper>
            <Typography variant={"h3"} sx={{mb: 2}}>Create a password to start your membership</Typography>
            <Typography variant={"h6"} fontWeight={'normal'}>Just a few more steps and you're finished!</Typography>
            <Typography variant={"h6"} fontWeight={'normal'}>We hate paperwork, too.</Typography>
            <form onSubmit={formik.handleSubmit}>
                {error && <Alert severity={"warning"} sx={{my: 1}}>
                    Sorry, but something went wrong. Please try again later.
                </Alert>}
                <TextField
                    sx={{mb: 1 / 2, mt: 2}}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                    sx={{my: 1 / 2}}
                    fullWidth
                    id="password"
                    name="password"
                    label="Add a password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && !!formik.errors.password}
                    helperText={formik.touched.password && formik.errors.password}
                />
                <FormControlLabel
                    control={<Checkbox
                        sx={{py: 1 / 2}}
                        checked={formik.values.noOffers}
                        onChange={formik.handleChange}
                    />}
                    label={`Please do not email me ${packageJson.officialName} special offers`}
                />
                <LoadingButton
                    sx={{mt: 1, mb: 1 / 4}}
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={Object.keys(formik.errors).length > 0}
                    loading={isPending}
                >
                    Next
                </LoadingButton>
            </form>
        </Box>
    </Box>)
}

export default PlanForm