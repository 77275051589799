import {useQuery} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import GoogleService from "../services/GoogleCalendarAPI";
import {useGetAccessToken} from "../../auth/GetAccessToken";
import {useGoogleAuthContext} from "../../auth/GoogleLogin";
import {useQueries} from "@tanstack/react-query";
import {EventInterface} from "../../firestore_interfaces/EventInterface";
import {getDaysInMonth} from "date-fns";
import {googleEventsToComboData} from "../../functions/CombineResults";
import {SelectedPeriodInterface} from "../../LocalInterfaces";
import {CategoryInterface} from "../../firestore_interfaces/CategoryInterface";

export function useGoogleCalendarsList(){
    const token = useGetAccessToken()
    const {googleUser} = useGoogleAuthContext()
    return useQuery<any, AxiosResponse>({
        queryKey: ["GoogleService", 'getCalendarList'],
        queryFn: () => GoogleService.getCalendarList(token),
        enabled: !!googleUser && !!token
    });
}

export function useGoogleCalendar(calendar_id: string | undefined){
    const token = useGetAccessToken()
    const {googleUser} = useGoogleAuthContext()
    return useQuery<any, AxiosResponse>({
        queryKey: ["GoogleService", 'getCalendar', calendar_id!],
        queryFn: () => GoogleService.getCalendar(token, calendar_id!),
        enabled: !!googleUser && !!token && !!calendar_id
    });
}

export function useGoogleCalendarEvents(googleCalIds: CategoryInterface[], period: SelectedPeriodInterface, maxResults: number){
    const token = useGetAccessToken()
    return useQueries<Array<any>, {
        data: EventInterface[],
        pending: boolean,
        error: any
    }>({
        queries: googleCalIds.map((calId: any) => {
            return {
                queryKey: ['GoogleService', 'getCalendarEvents', calId.google, period.year, period.month, maxResults],
                queryFn: () => GoogleService.getCalendarEvents(calId, maxResults, `${period.year}-${period.month}-01T10:00:00Z`, `${period.year}-${period.month}-${getDaysInMonth(new Date(period.year, period.month - 1))}T10:00:00Z`, calId?.group_id, token)
            }
        }),
        combine: results => googleEventsToComboData(results)
    })
}