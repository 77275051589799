import {
    Avatar,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {AccountCircle, ExitToApp} from "@mui/icons-material";
import {getAuth, signOut} from "firebase/auth";
import {app} from "./FirebaseConfig";
import {useAuthedUserContext} from "../containers/auth/AuthedRoute";
import {useNavigate} from "react-router-dom";
import {MenuButtonIconProps, MenuButtonProps} from "../theming/StyleContstants";


function UserMenuAvatar() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const auth = getAuth(app)
    const user: any = useAuthedUserContext()
    const navigate = useNavigate()
    return (
        <>
            <Tooltip title={<Typography style={{fontSize: 13}}>User</Typography>}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{...MenuButtonProps}}
                >
                    <Avatar sx={MenuButtonIconProps}/>
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{maxWidth: '300px'}}
            >
                <MenuItem disabled sx={{textAlign: 'center'}}>{user?.email}</MenuItem>
                <Divider/>
                <MenuItem onClick={() => navigate('/profile')}>
                    <ListItemIcon>
                        <AccountCircle fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>User Account</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => signOut(auth)}>
                    <ListItemIcon>
                        <ExitToApp fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </>
    )
}

export default UserMenuAvatar
