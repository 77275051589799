import {Alert, Box, Button, CircularProgress, Paper} from "@mui/material";
import {AxiosHeaders} from "axios";
import {CalendarInterface} from "../../../../firestore_interfaces/CalendarInterface";
import {useEffect, useMemo, useState} from "react";
import {Download} from "@mui/icons-material";
import CRUDAlert from "../../../CRUDAlert";
import {CategoryInterface} from "../../../../firestore_interfaces/CategoryInterface";
import {getLocaleLanguage} from "../Locales";
import RenderGAPIError from "../../../GAPIError";
import {useGoogleCalendarEvents} from "../../../../api/hooks/googleGet";
import {useGetPdfUrl} from "../../../../api/hooks/cloudFuncs";
import {useGetArrayEvents} from "../../../../api/hooks/dbEventList";
import {useGetCalendarEventGroups} from "../../../../api/hooks/dbCalendar";

function downloadFile(fileURL: any, filename: string) {
    let link = window.document.createElement('a')
    // Browsers that support HTML5 download attribute
    link.href = fileURL
    if (link?.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        link.download = filename
    }
    link.dispatchEvent(new MouseEvent('click'));
    // document.body.appendChild(link)
}

function Generate({
                      selectedEventsGroups,
                      period,
                      calendar
                  }: { selectedEventsGroups: string[], period: any, calendar: CalendarInterface }) {

    const mm_px_factor = 2
    const dims = {width: 297, height: 210}

    const primLang = getLocaleLanguage(calendar.languagePrimary)?.code
    const secLang = getLocaleLanguage(calendar.languageSecondary)?.code

    const {data: eventGroups, isPending: eventGroupsPending} = useGetCalendarEventGroups(calendar)
    // const {
    //     data: annualEvents,
    //     pending: annualEventsPending,
    //     // error: annualEventsError
    // } = useGetArrayEvents(selectedEventsGroups, period, 'annual')
    // const {
    //     data: singleEvents,
    //     pending: singleEventsPending,
    //     // error: singleEventsError
    // } = useGetArrayEvents(selectedEventsGroups, period, 'single')

    const googleCalGroupsWithLangAPI: CategoryInterface[] = useMemo(() => {
        if (eventGroups) {
            return eventGroups.filter((g: CategoryInterface) => g?.source === 'api' && g?.languages && g.languages.filter(l => l?.google && [primLang, secLang].includes(l.language)).length > 0)
        } else {
            return []
        }
    }, [eventGroups, primLang, secLang])

    const googleCalIDsPrim = useMemo(
        () => {
            const ids: any[] = []
            googleCalGroupsWithLangAPI.forEach(g => {
                const langFind = g.languages.filter(l => l.language === primLang)
                const lang = langFind.length > 0 ? langFind[0] : undefined
                if (lang?.google) {
                    ids.push({groupId: g._id, google: lang?.google})
                }
            })
            return ids
        }, [googleCalGroupsWithLangAPI, primLang])
    // TODO: SecLang


    // TODO: timezone offset calc
    const {
        data: googleEvents,
        pending: googleEventsPending,
        error: googleEventsError
    } = useGoogleCalendarEvents(googleCalIDsPrim, period, 100)

    const {mutate: getPdfUrl, error: errorPdf, isPending: loadingPdf} = useGetPdfUrl()

    const [pdfUrl, setPdfUrl] = useState<any>('')
    // useEffect(() => {
    //     if (!singleEventsPending && !annualEventsPending && !eventGroupsPending && !googleEventsPending) getPdfUrl({
    //         calendar,
    //         events: [...singleEvents, ...annualEvents, ...googleEvents],
    //         groups: eventGroups,
    //         period
    //     }, {
    //         onSuccess: url => setPdfUrl(url)
    //     })
    // }, [getPdfUrl, singleEventsPending, annualEventsPending, eventGroupsPending, googleEventsPending, singleEvents, annualEvents, googleEvents, calendar, eventGroups, period])

    return (
        <>
            {(googleEventsError && googleEventsError.length > 0) &&
                <Alert severity={'error'}><RenderGAPIError error={googleEventsError[0]}/></Alert>}
            <Paper sx={{width: 1, mb: 2, p: 1, maxWidth: 'sm'}} variant={'outlined'}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    {/*https://tinytip.co/tips/html-pdf-params/*/}
                    {loadingPdf && <CircularProgress/>}

                    {errorPdf && <CRUDAlert object={errorPdf}/>}
                    {!pdfUrl && !errorPdf && <CRUDAlert object={{
                        status: 0,
                        data: 'Unknown Error',
                        statusText: 'TODO',
                        headers: {},
                        request: {},
                        config: {headers: {} as AxiosHeaders}
                    }}/>}
                    {!loadingPdf && !errorPdf && pdfUrl && <iframe
                        title={'CalendarPDF'}
                        src={pdfUrl + '#toolbar=0&view=Fit'}
                        width={dims.width * mm_px_factor}
                        height={dims.height * mm_px_factor}
                    />}
                </Box>
            </Paper>
            <Button
                startIcon={<Download/>}
                color={'success'}
                variant={'outlined'}
                onClick={() => {
                    downloadFile(pdfUrl, 'test.pdf')
                }}
            >
                Download
            </Button>
        </>
    )
}

export default Generate