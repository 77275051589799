import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormHelperText,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useAuthedUserContext} from "../../auth/AuthedRoute";
import {InsertEmoticon, Label, Public} from "@mui/icons-material";
import React, {useState} from "react";
import {CategoryInterface} from "../../../firestore_interfaces/CategoryInterface";
import IconPicker from "./IconPicker";
import {LoadingButton} from "@mui/lab";
import {getCodeLanguage, languages} from "../calendar/Locales";
import {useAddEventsGroup} from "../../../api/hooks/dbEventsGroup";
import {useUpdateUser} from "../../../api/hooks/dbUser";

function AddEventGroupModal({open, setOpen}: { open: boolean, setOpen: any }) {
    const navigate = useNavigate()
    const user: any = useAuthedUserContext()

    const {mutate: addEventsGroup, isPending} = useAddEventsGroup(user?.uid)
    const {mutate: updateUser} = useUpdateUser(user?.uid)

    const [newCategory, setNewCategory] = useState<Partial<CategoryInterface>>({})

    // const buttonSx = {flex: 1, display: 'flex', flexDirection: 'column', m: 1}

    // function handleChange(event: React.MouseEvent<HTMLElement>, value: string, property: string) {
    //     if (property === 'source' && value === 'annual') {
    //         setNewCategory(g => {
    //             return {...g, source: 'annual', labelIcon: "icon-label"}
    //         })
    //     } else if (property === 'type' && value === 'label') {
    //         setNewCategory(g => {
    //             return {...g, labelIcon: 'label', iconImg: undefined}
    //         })
    //     } else if (property === 'type' && value === 'icon') {
    //         setNewCategory(g => {
    //             return {...g, labelIcon: 'icon', languages: undefined}
    //         })
    //     } else if (value !== null) {
    //         setNewCategory(g => {
    //             return {...g, [property]: value}
    //         })
    //     }
    // }

    const namePlaceholder = () => {
        if (newCategory?.annual) {
            return 'Family Birthdays'
        } else if (!newCategory?.annual) {
            if (newCategory?.icon) {
                return 'Fasting Day'
            } else {
                return 'Parties'
            }
        } else {
            return 'My Events'
        }
    }

    return <Dialog open={open}>
        <DialogTitle sx={{width: 400}}>Add new Event Group</DialogTitle>
        <DialogContent>
            <DialogContentText>
                You will be able to add any event group when generating your calendar.
                Each event group should share a common theme.
            </DialogContentText>
            <Typography
                variant={'subtitle2'}
                sx={{mt: 1, mb: 1 / 3}}
            >
                Data Entry / Source
            </Typography>
            <ToggleButtonGroup
                exclusive
                // onChange={(e, val) => handleChange(e, val, 'source')}
                value={newCategory?.source || ''}
                sx={{mt: 1, mb: 1 / 2}}
            >
                {/*TODO: move to later (changeable)*/}
                {/*<ToggleButton value={'annual'}>*/}
                {/*    <EventRepeat sx={{m: 1}}/>*/}
                {/*    Recurring Annually*/}
                {/*</ToggleButton>*/}
                {/*<ToggleButton value={'single'}>*/}
                {/*    <Event sx={{m: 1}}/>*/}
                {/*    Singular Events*/}
                {/*</ToggleButton>*/}
                <ToggleButton value={'api'}>
                    <Public sx={{m: 1}}/>
                    Enter Events into Caleneer
                </ToggleButton>
                <ToggleButton value={'api'}>
                    <Public sx={{m: 1}}/>
                    External Connection (Google)
                </ToggleButton>
            </ToggleButtonGroup>
            <Box sx={{display: 'flex'}}>
                <Box sx={{mr: 2}}>
                    <Typography
                        variant={'subtitle2'}
                        sx={{mt: 1, mb: 1 / 3}}
                    >
                        Event Type
                    </Typography>
                    <ToggleButtonGroup
                        exclusive
                        //TODO: At least one must be enabled
                        // onChange={(e, val) => handleChange(e, val, 'type')}
                        // value={newCategory?.label || ''}
                        sx={{my: 1 / 2}}
                    >
                        <ToggleButton
                            value={'icon'}
                            // disabled={newCategory?.source === 'annual'}
                            sx={{display: 'flex', flexDirection: 'column'}}
                        >
                            <InsertEmoticon sx={{m: 1}}/>
                            Icon Only
                        </ToggleButton>
                        <ToggleButton
                            value={'icon-label'}
                            sx={{display: 'flex', flexDirection: 'column'}}
                        >
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <InsertEmoticon sx={{m: 1}}/>
                                <Label sx={{m: 1}}/>
                            </Box>
                            Icon and Label

                        </ToggleButton>
                        <ToggleButton
                            value={'label'}
                            sx={{display: 'flex', flexDirection: 'column'}}
                        >
                            <Label sx={{m: 1}}/>
                            Label Only
                        </ToggleButton>

                    </ToggleButtonGroup>
                </Box>
                <Box>
                    <Typography
                        variant={'subtitle2'}
                        sx={{mt: 1, mb: 1 / 3}}
                    >
                        Icon
                    </Typography>
                    <Box sx={{mt: 1 / 2}}>
                        <IconPicker
                            icon={newCategory?.iconImg}
                            setNewGroup={setNewCategory}
                            disabled={!newCategory?.icon}
                            pad={2}
                        />
                        {newCategory?.icon && <FormHelperText error={!newCategory?.iconImg}>
                            Required for 'Icon' type
                        </FormHelperText>}
                    </Box>
                </Box>
            </Box>
            <FormHelperText><strong>With Label:</strong> Events appear on date with label in configured
                language(s).</FormHelperText>
            <FormHelperText><strong>Icon Only:</strong> Events will appear on date as an icon only (in the top
                right
                corner). Use this for a frequently recurring event type. <i>(Not available for annual
                    events)</i></FormHelperText>
            <Typography
                variant={'subtitle2'}
                sx={{mt: 1, mb: 1 / 3}}
            >Languages
            </Typography>
            <Autocomplete
                disabled={!newCategory?.label}
                multiple
                options={Object.entries(languages).map(([code, l]) => {
                    return {label: l.label, value: code}
                })}
                renderInput={(params) => {
                    return <TextField {...params} placeholder="Language"
                                      error={!newCategory?.languages || newCategory?.languages.length === 0}/>
                }}
                value={newCategory?.languages ? newCategory.languages.map(l => {
                    return {
                        label: getCodeLanguage(l.language)!.label,
                        value: l.language
                    }
                }) : []}
                onChange={(event: any, newValue: any[]) => {
                    if (newValue) {
                        setNewCategory(g => {
                            return {
                                ...g, languages: newValue.filter(v => v).map(v => {
                                    return {language: v.value}
                                })
                            }
                        })
                    }
                }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
            />
            <FormHelperText><strong>Note:</strong> All events in this group will require a label for each language
                enabled</FormHelperText>
            <Typography
                variant={'subtitle2'}
                sx={{mt: 1, mb: 1 / 3}}
            >
                Group Name
            </Typography>
            <TextField
                fullWidth
                value={newCategory?.name || ''}
                onChange={e => setNewCategory(g => {
                    return {...g, name: e.target.value}
                })}
                placeholder={namePlaceholder()}
            />
            <FormHelperText><i>(Reference only)</i> Used to select groups for generating calendar
            </FormHelperText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    setOpen(false)
                    setNewCategory({})
                }}
                color={'error'}
            >
                Cancel
            </Button>
            <LoadingButton
                loading={isPending}
                onClick={() => addEventsGroup(newCategory, {
                    onSuccess: (events: CategoryInterface) => {
                        updateUser({[`events.${events._id}`]: events.name}, {
                            onSuccess: async () => setOpen(false)
                        })
                        navigate(events._id)
                    },
                    onSettled: () => setNewCategory({})
                })}
                color={'success'}
                disabled={
                    Object.keys(newCategory).length < 0 ||
                    newCategory?.source === undefined ||
                    (newCategory?.label === undefined && newCategory?.icon === undefined) ||
                    newCategory?.name === undefined ||
                    newCategory.name?.length < 1 ||
                    newCategory?.languages === undefined ||
                    newCategory?.languages?.length < 1 ||
                    (newCategory.icon && !newCategory?.iconImg)
                }
            >
                Add Event Group
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

export default AddEventGroupModal