import {formatDuration, getDaysInMonth, intervalToDuration} from "date-fns";
import {EventInterface} from "../../../firestore_interfaces/EventInterface";

export function ageCalc(event: Partial<EventInterface>) {
    if (event?.est && event.month && event.day) {
        const today = new Date()
        const enteredDate = new Date(event.est, event.month - 1, event.day)
        const duration = intervalToDuration({
            start: today,
            end: enteredDate
        })
        // console.log(today, enteredDate, duration)
        const negate = today < enteredDate ? '-' : ''
        const yrs = formatDuration(duration, {format: ['years']})
        const months = formatDuration(duration, {format: ['months']})
        // FIXME: 1 day in future rounds for hours
        const days = formatDuration(duration, {format: ['days']})
        if (yrs) {
            return negate + yrs
        } else if (months) {
            return negate + months
        } else {
            return negate + days
        }
    } else {
        return 'Unknown'
    }
}

export function invalidDay(event: Partial<EventInterface>) {
    if (event?.day) {
        if (event?.month) {
            //known month
            let days = getDaysInMonth(new Date(2000, event?.month - 1))
            if (event?.est) {
                //known year
                days = getDaysInMonth(new Date(event?.est, event?.month - 1))
            }
            if (!(1 <= event.day && event.day <= days)) return 'Invalid Date'
        } else {
            // unknown month
            if (!(1 <= event.day && event.day <= 31)) return 'Invalid Date'
        }
    } else {
        return 'Empty Value'
    }
}

export function invalidMonth(event: Partial<EventInterface>) {
    if (event?.month) {
        if (!(1 <= event.month && event.month <= 12)) return 'Invalid Month'
    } else {
        return 'Empty Value'
    }
}

export function invalidEst(event: Partial<EventInterface>) {
    if (event?.est) {

        console.log('invalid', new Date(event?.est, event.month! - 1, event?.day))
        if (event.est < 1500) return 'Minimum year is 1500'
        if (event?.month && event?.day && new Date(event?.est, event.month - 1, event?.day) > new Date()) return 'Established events cannot be in future'
    } else {
        return ''
    }
}