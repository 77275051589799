import React from "react";
import {ThemeProvider} from '@mui/material/styles';
import {RouterProvider} from "react-router-dom";
import {QueryCache, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {router} from "../Router";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {defaultTheme} from "../theming/Theme";
import {createTheme} from "@mui/material";
import {SnackbarProvider} from "notistack";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import GoogleAuth from "../auth/GoogleLogin";

const Home = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false
            }
        },
        queryCache: new QueryCache({
            onError: (error, query) => {
                // TODO: Handle API errors globally (in client config) #5
                // https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
                // if (query.meta.errorMessage) {
                //     toast.error(query.meta.errorMessage)
                // }
            },
        }),
    })

    const theme = createTheme(defaultTheme)

    return (
        <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={5}
                    autoHideDuration={3000} //3s
                >
                    <GoogleAuth>
                        <ThemeProvider theme={theme}>
                            <RouterProvider router={router}/>
                            <ReactQueryDevtools/>
                        </ThemeProvider>
                    </GoogleAuth>
                </SnackbarProvider>
            </LocalizationProvider>
        </QueryClientProvider>
    );
}

export default Home
