import googleAPIClient from "./GoogleAPIClient";

async function getCalendarList(token?: string, calendar_id?: string) {
    return await googleAPIClient(token).get<any>(`/users/me/calendarList${calendar_id ? '/' + calendar_id : ''}`)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response))
}

async function getCalendar(token: string, calendar_id: string) {
    return await googleAPIClient(token).get<any>(`/calendars/${calendar_id}`)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response))
}

async function getCalendarEvents(calendar_id: string, max_results = 100, time_min?: string, time_max?: string, groupId?: string, token?: string) {
    let params = `?maxResults=${max_results}`
    if(time_min){
        params = params+`&timeMin=${time_min}`
    }
    if(time_max){
        params = params+`&timeMax=${time_max}`
    }
    return await googleAPIClient(token).get<any>(`/calendars/${calendar_id}/events${params}`)
        .then(response => {
            return{...response.data, groupId: groupId}
        })
        .catch(error => Promise.reject(error.response))
}

async function getUserSetting(token: string, setting: string){
    return await googleAPIClient(token).get<any>(`/users/me/settings/setting${setting}`)
        .then(response => response.data)
        .catch(error => Promise.reject(error.response))
}

const GoogleService = {
    getCalendarList,
    getCalendarEvents,
    getUserSetting,
    getCalendar
}

export default GoogleService
