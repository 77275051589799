import {Alert, AlertTitle, Box, Button, Paper, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import {useState} from "react";
import {useParams} from "react-router-dom";
import SelectMonth from "./GenerateSteps/SelectMonth";
import SelectEvents from "./GenerateSteps/SelectEvents";
import Generate from "./GenerateSteps/Generate";
import {useGetCalendar, useUpdateCalendar} from "../../../api/hooks/dbCalendar";

const steps = [
    {
        label: 'Select Month',
        description: `Which month would you like to generate?`,
    },
    {
        label: 'Select Event Groups',
        description:
            'Select categories groups that you would like to include on your calendar'
    },
    {
        label: 'Generate',
        description: ``,
    },
];

function CalendarGenerate() {

    const [selectedPeriod, setSelectedPeriod] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    })

    const {calendar_id} = useParams();

    const {data: calendar} = useGetCalendar(calendar_id, {})

    const {mutate: updateCalendar} = useUpdateCalendar(calendar_id)

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        let difference = []
        if (calendar?.events) {
            difference = calendar.events.filter((x: string) => !selectedEventGroups.includes(x))
                .concat(selectedEventGroups.filter(x => !calendar.events.includes(x)))
        }
        if (activeStep === 1 && (calendar?.events === undefined || difference.length > 0)) {
            updateCalendar({events: selectedEventGroups})
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [selectedEventGroups, setSelectedEventGroups] = useState<string[]>([]);

    function stepContent(step: number) {
        switch (step) {
            case 0:
                return <SelectMonth selected={selectedPeriod} setSelected={setSelectedPeriod}/>
            case 1:
                return <SelectEvents
                    selectedPeriod={selectedPeriod}
                    selectedEventsGroups={selectedEventGroups}
                    setSelectedEventGroups={setSelectedEventGroups}
                    calendar={calendar}
                />
            case 2:
            default:
                return <Generate
                    period={selectedPeriod}
                    selectedEventsGroups={selectedEventGroups}
                    calendar={calendar}
                />
        }
    }

    return (
        <Box sx={{width: 1, maxWidth: 'lg', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
            <Typography
                variant={'h1'}
                textAlign={'center'}
                sx={{mb: 2}}
            >
                Generate Calendar
            </Typography>
            <Stepper activeStep={activeStep} orientation="vertical" sx={{width: 1}}>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                <Typography variant="caption">{step.description}</Typography>
                            }
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Box sx={{
                                mb: 2,
                                width: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                pt: 1
                            }}>
                                {stepContent(index)}
                                {(index === 1 && selectedEventGroups.length < 1) &&
                                    <Alert severity={"warning"} sx={{mt: 2}}>
                                        <AlertTitle>Please select <strong>at least one</strong> <i>Event Group</i> to be
                                            included on the calendar</AlertTitle>
                                    </Alert>}
                                <Box sx={{mt: 2}}>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{mr: 1}}
                                        disabled={(index === 1 && selectedEventGroups.length < 1)}
                                    >
                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                    </Button>
                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                    >
                                        Back
                                    </Button>
                                </Box>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{p: 3}}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
                        Reset
                    </Button>
                </Paper>
            )}
        </Box>
    )
}

export default CalendarGenerate