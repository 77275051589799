import {Box, InputAdornment, Typography} from "@mui/material"
import packageJson from '../package.json'
import {Architecture, DesignServices} from "@mui/icons-material";
import {LockedTextField} from "./theming/LockedTextField";

const About = () => {
    return (
        <Box sx={{
            maxWidth: 'sm',
            width: 1,
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <Box sx={{p: 1, alignItems: 'center', display: "flex", flexDirection: 'column',}}>
                <Typography variant="h4" style={{fontWeight: 800}}>
                    {packageJson.officialName}
                </Typography>
                <Typography variant="h6" style={{fontWeight: 300}}>
                    a calendar creation solution
                </Typography>
            </Box>
            <LockedTextField
                label="Version"
                value={packageJson.version}
                disabled
                margin="normal"
                fullWidth
            />
            <Box sx={{width: 1, display: "flex"}}>
                <LockedTextField
                    label="Solutions Architect"
                    value={"Adrian Metlenko"}
                    disabled
                    margin="normal"
                    fullWidth
                    sx={{mr: 1}}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Architecture/></InputAdornment>,
                    }}
                />
                <LockedTextField
                    label="Frontend Developer"
                    value={"Adrian Metlenko"}
                    disabled
                    margin="normal"
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><DesignServices/></InputAdornment>,
                    }}
                />
            </Box>
        </Box>
    )

}

export default About
