import {Box, Paper, Typography} from "@mui/material";
import {ReactNode} from "react";

function AccountSection({name, rightElement, children}: {name: string, children: any, rightElement?: ReactNode}) {
    return <Paper sx={{p: 2, width: 1, mb: 2}} variant={'outlined'}>
        <Box sx={{display: 'flex', width: 1, justifyContent: 'space-between'}}>
            <Typography variant={"h5"} sx={{mb: 1}}>{name}</Typography>
            {rightElement}
        </Box>
        {children}
    </Paper>
}
export default AccountSection