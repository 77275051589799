import {Box, Button, TextField, Typography} from "@mui/material";
import {fetchSignInMethodsForEmail, getAuth} from "firebase/auth";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {useFormik} from 'formik';
import * as yup from 'yup';
import packageJson from '../../package.json'

function Landing() {
    const auth = getAuth()
    const navigate = useNavigate()
    const location = useLocation()

    const SignupSchema = yup.object({
        email: yup
            .string()
            .email('Please enter a valid email address.')
            .required('Email is required.'),
    });

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: SignupSchema,
        onSubmit: async (values) => {
            fetchSignInMethodsForEmail(auth, values.email).then(methods => {
                if (methods.length > 0) { // user exists
                    navigate('/login', {state: values})
                } else { // new user detected
                    navigate('/signup/regform', {state: values})
                }
            })
        }
    });

    if (location.pathname !== '/'){
        return <Navigate to="/" replace={true} />
    }
    return (<Box sx={{display: 'flex', width: 1, alignItems: 'center', flexDirection: 'column'}}>
        <Box sx={{width: 1, display: 'flex', mt: 2, justifyContent: 'center'}}>
            <Box sx={{width: 1, display: 'flex', maxWidth: 'md', mx: 2, justifyContent: 'space-between'}}>
                <Typography sx={{textAlign: 'center'}}>{`${packageJson.officialName}`}</Typography>
                <Button
                    variant={'contained'}
                    onClick={() => navigate('/login')}
                >
                    Sign in
                </Button>
            </Box>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', width: 360, m: 10}}>
            <Typography variant={"h3"} sx={{textAlign: 'center', mb: 2}}>Personalise your calendars - customise, print,
                and share </Typography>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    sx={{my: 1 / 2}}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <Button
                    sx={{mt: 1, mb: 1 / 4}}
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={Object.keys(formik.errors).length > 0}
                >
                    Get started
                </Button>
            </form>
        </Box>
    </Box>)
}

export default Landing