import {useEffect, useState} from "react";
import {useGoogleAuthContext} from "./GoogleLogin";

export function useGetAccessToken() {
    const {gapi} = useGoogleAuthContext()
    const [accessToken, setAccessToken] = useState('');
    useEffect(() => {
        (async () => {
            if(gapi){
                const token = await gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
                setAccessToken(token)
            }
        })()
    }, [gapi]);
    return accessToken;
}