import {blue, orange} from "@mui/material/colors";

export const paperElevation = 3
export const defaultTheme = {
    typography: {
        h1: {
            //page titles
            fontSize: 28,
            fontWeight: 600,
            lineHeight: 2
        },
        h2: {
            //page sub-titles
            fontSize: 24,
            fontWeight: 600,
            lineHeight: 1.5
        },
        h3: {
            fontSize: 22,
            fontWeight:600
        },
        h4: {
            fontSize: 20,
            fontWeight:600
        },
        h5: {
            fontSize: 18,
            fontWeight:600
        },
        h6: {
            fontSize: 16,
            fontWeight:600
        },
        subtitle1: {
            fontWeight: 600,
        },
        subtitle2: {
            fontWeight: 800,
        },
        body2: {
            // Card info
        }
    },
    palette: {
        primary: blue,
        secondary: orange,
    },
    spacing: 10,

    // Using default as custom breaks grid sizes
    // breakpoints: {
    //     values: {
    //         xs: 0,
    //         sm: 600, = Mobile
    //         md: 900, = Tablet
    //         lg: 1200, = Laptop
    //         xl: 1536, = Desktop
    //     },
    // },
}
