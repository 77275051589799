import {LanguageAPI} from "../../../../firestore_interfaces/LanguageAPI";
import {Cancel, Edit, Link, Save} from "@mui/icons-material";
import {getCodeLanguage} from "../../calendar/Locales";
import {Avatar, Box, CircularProgress, Divider, IconButton, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {CategoryInterface} from "../../../../firestore_interfaces/CategoryInterface";
import {useParams} from "react-router-dom";
import {useUpdateEventGroup} from "../../../../api/hooks/dbEventsGroup";

function LanguageGAPI({lang, eventGroup}: { lang: LanguageAPI, eventGroup: CategoryInterface }) {

    const {events_id} = useParams();

    const [edit, setEdit] = useState(false)
    const [language, setLanguage] = useState(lang)


    // FIXME: Reconsider if needed - maybe convert to single isPublic? hook
    // const {data: calendarPublic, error: getErrorPublic, isLoading: getLoadingPublic} = useQuery<any, AxiosResponse>({
    //     queryKey: ["GoogleService", 'getCalendarEvents', 'public', lang.google, 1],
    //     queryFn: () => GoogleService.getCalendarEvents(lang.google!, 1),
    //     // only needed to confirm once
    //     enabled: !!lang?.google && lang?.public === undefined
    // });
    //
    // const {data: calendarPrivate, error: getErrorPrivate, isLoading: getLoadingPrivate} = useGoogleCalendar(lang.google)

    useEffect(() => {
        setLanguage(lang)
    }, [lang, eventGroup]);

    const {mutate: updateEventGroup, isPending: updateLoading} = useUpdateEventGroup(events_id)

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center', py: 1}}>
                <Avatar
                    // variant="rounded"
                    // sx={{height: 30, width: 60}}
                    src={`https://flagcdn.com/h120/${getCodeLanguage(language.language)?.flag}.png`}
                    alt={getCodeLanguage(language.language)?.label}
                />
                <TextField
                    sx={{mx: 1}}
                    label={'Language'}
                    value={getCodeLanguage(language.language)?.label}
                    disabled
                />
                <TextField
                    fullWidth
                    sx={{mr: 1}}
                    // TODO: validate google
                    label={'Google Calendar ID'}
                    disabled={!edit}
                    multiline
                    value={language?.google || ''}
                    onChange={e => setLanguage(l => {
                        return {...l, google: e.target.value}
                    })}
                    InputProps={{
                        startAdornment: <IconButton
                            disabled={!language?.google}
                            onClick={() => window.open(`https://calendar.google.com/calendar/u/0/embed?src=${language.google}`, "_blank")}>
                            <Link sx={{color: '#000000 !important'}}/>
                        </IconButton>,
                        endAdornment: <>{updateLoading ? <CircularProgress/> : <>{edit ?
                            <IconButton
                                onClick={() => {
                                    if (lang?.google === language?.google) {
                                        setEdit(e => !e)
                                    } else {
                                        updateEventGroup({
                                            ...eventGroup,
                                            languages: [...eventGroup.languages.filter(l => l.language !== language.language), language]
                                        }, {onSuccess: () => setEdit(e => !e)})

                                    }
                                }}
                            >
                                {lang?.google === language?.google ? <Cancel sx={{color: '#000000 !important'}}/> :
                                    <Save sx={{color: '#000000 !important'}}/>}
                            </IconButton> :
                            <IconButton
                                color={'default'}
                                onClick={() => setEdit(e => !e)}
                            >
                                <Edit sx={{color: '#000000 !important'}}/>
                            </IconButton>
                        }</>}</>
                    }}
                />
                {/*<GapiPrivacy lang={language} errorPrivate={getErrorPrivate} errorPublic={getErrorPublic}*/}
                {/*             calendarPrivate={calendarPrivate} calendarPublic={calendarPublic}*/}
                {/*             isLoading={getLoadingPrivate || getLoadingPublic}/>*/}
                {/*<GapiHealth lang={language} error={getErrorPrivate} calendar={calendarPrivate}*/}
                {/*            isLoading={getLoadingPrivate}/>*/}
            </Box>
            <Divider/>
        </>
    )
}

export default LanguageGAPI