import {createBrowserRouter,} from "react-router-dom";
import Login from "./containers/auth/Login";
import AuthedRoute from "./containers/auth/AuthedRoute";
import ErrorPage from "./containers/ErrorPage";
import CalendarConfig from "./containers/path/calendar/CalendarConfig";
import UserProfile from "./containers/path/user/UserProfile";
import CalendarSelect from "./containers/path/calendar/CalendarSelect";
import EventGroups from "./containers/eventList/EventGroups";
import Events from "./containers/path/categories/Events";
import About from "./About";
import CalendarGenerate from "./containers/path/calendar/CalendarGenerate";
import CalendarGenCon from "./containers/path/calendar/CalendarGenCon";
import LoginHelp from "./containers/auth/LoginHelp";
import RegisterForm from "./containers/auth/RegisterForm";
import PlanForm from "./containers/auth/PlanForm";
import Landing from "./containers/Landing";
import Dashboard from "./containers/Dashboard";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <AuthedRoute/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <Dashboard/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: "profile/",
                element: <UserProfile/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: "about/",
                element: <About/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: "calendar/",
                element: <CalendarSelect/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: "calendar/:calendar_id",
                element: <CalendarGenCon/>,
                errorElement: <ErrorPage/>,
                children: [
                    {
                        path: "configure",
                        element: <CalendarConfig/>,
                        errorElement: <ErrorPage/>
                    },
                    {
                        path: "generate",
                        element: <CalendarGenerate/>,
                        errorElement: <ErrorPage/>
                    }
                ]
            },
            {
                path: "events",
                element: <EventGroups/>,
                errorElement: <ErrorPage/>,
                children: [
                    {
                        path: ":events_id",
                        element: <Events/>,
                        errorElement: <ErrorPage/>,
                    }
                ]
            }
        ],
    },
    {
        path: "/login",
        element: <Login/>
    },
    {
        path: "/loginHelp",
        element: <LoginHelp/>
    },
    {
        path: "/signup/regform",
        element: <RegisterForm/>
    },
    {
        path: "/signup/planform",
        element: <PlanForm/>
    },
    {
        path:"/*",
        element: <Landing/>
    }
]);