import {getYear} from "date-fns";

export const dateSingle = {
    disablePast: true,
    maxDate: new Date(getYear(new Date()) + 10, 0, 1)
}

export const dateAnnual = {
    disableFuture: true,
    // maxDate: 2023
}