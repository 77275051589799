import React, {createContext, useContext, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {getAuth, onAuthStateChanged, User} from 'firebase/auth'
import {app} from "../../auth/FirebaseConfig";
import {Box, CircularProgress, Typography} from "@mui/material";
import Header from "../Header";
import {appBar} from "../../theming/StyleContstants";
import Landing from "../Landing";

export const UserContext = createContext<User | undefined>(undefined);
export const useAuthedUserContext = () => useContext(UserContext)

function AuthedRoute() {

    const auth = getAuth(app)
    const [user, setUser] = useState<User | null | undefined>(undefined)

    onAuthStateChanged(auth, (user) => {
        console.log('Authed User', user)
        setUser(user)
    })

    let loading = false
    if (user === undefined) {
        loading = true
    }
    if (!user || loading) {
        return <Landing/>
        // return <Navigate to="/login" replace={true}/>
    }
    const topOffset = appBar
    return <UserContext.Provider value={user}>
        <Header/>
        <Box sx={{
            mt: topOffset / 10,
            overflow: "auto",
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: `calc(100vh - ${topOffset}px - 40px)`, //40px = 5 * 2 padding * (top + bottom)
        }}>
            {loading && <Box sx={{m: 10, display: 'flex', flexDirection: 'column'}}>
                <CircularProgress sx={{m: 2}}/>
                <Typography>Loading...</Typography>
            </Box>}
            {!loading && <Outlet/>}
        </Box>
    </UserContext.Provider>
}

export default AuthedRoute