import {
    collection,
    doc,
    FirestoreError,
    getDoc, getDocs,
    getFirestore, query,
    serverTimestamp,
    setDoc,
    updateDoc, where
} from "firebase/firestore";
import {app} from "../../auth/FirebaseConfig";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuthedUserContext} from "../../containers/auth/AuthedRoute";
import {CalendarInterface} from "../../firestore_interfaces/CalendarInterface";

export function useAddCalendar(){

    const queryClient = useQueryClient()
    const user = useAuthedUserContext()

    return useMutation<any, FirestoreError, any, () => void>({
        mutationFn: async (calendar) => {
            const db = getFirestore(app);
            const newCalRef = doc(collection(db, "calendars"));
            await setDoc(newCalRef, {
                ...calendar, _id: newCalRef.id, userId: [user!.uid], createdAt: serverTimestamp()
            })
            return {...calendar, _id: newCalRef.id}
        },
        onSuccess: async calendar => {
            await queryClient.invalidateQueries({queryKey: ['calendars'!]})
            // TODO: make transaction
            // updateUser({[`calendars.${calendar._id}`]: calendar.name})
        },
        // onError: error => {
        //     console.log(error)
        //     enqueueSnackbar(error.message, {variant: 'error'})
        // },
    })
}

export function useGetCalendar(calendar_id: string | undefined, placeholderData: any) {

    const db = getFirestore(app)

    return useQuery<any, FirestoreError>({
        queryKey: ["calendars", calendar_id],
        queryFn: () => getDoc(doc(db, "calendars", calendar_id!)),
        enabled: !!calendar_id,
        select: data => data?.data(),
        placeholderData: {data: () => placeholderData}
        // onError: error => {
        //     console.error(error)
        //     enqueueSnackbar(error.message, {variant: 'error'})
        // }
    })
}

export function useGetCalendarEventGroups(calendar: CalendarInterface) {

    const db = getFirestore(app)
    const user: any = useAuthedUserContext()
    const userId = user?.uid

    return useQuery<any, FirestoreError>({
        queryKey: ["event", 'in', calendar._id],
        queryFn: () => {
            const groups = query(
                collection(db, "events"),
                where('_id', 'in', calendar.events),
                where('userId', 'array-contains', userId)
            )
            return getDocs(groups)
        },
        enabled: !!calendar._id && !!calendar?.events && !!userId,
        select: data => {
            if (data) {
                const temp: any[] = []
                data.docs.forEach((doc: any) => {
                    const e = doc.data()
                    temp.push({...e, id: e._id})
                })
                return (temp)
            } else {
                return []
            }
        }
    })
}

export function useUpdateCalendar(calendar_id: string | undefined) {

    const db = getFirestore(app)
    const queryClient = useQueryClient()

    return useMutation<any, FirestoreError, any, () => void>({
        mutationFn: async (calendar) => {
            const eventsRef = doc(db, "calendars", calendar_id!)
            return updateDoc(eventsRef, {
                    ...calendar,
                    updatedAt: serverTimestamp()
                }
            )
        },
        // onError: error => {
        //     console.error(error)
        //     enqueueSnackbar(error.message, {variant: 'error'})
        // },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['calendars', calendar_id!]})
            // enqueueSnackbar('Save Successful', {variant: 'success'})
        }
    })
}

