import {Alert, AlertTitle, Box} from "@mui/material";
import LanguageGAPI from "./LanguageGAPI";
import React from "react";
import {CategoryInterface} from "../../../../firestore_interfaces/CategoryInterface";
import packageJson from '../../../../../package.json'

function GapiConfig({eventGroup}: { eventGroup: CategoryInterface}){
    // TODO give example of matching description
    console.log('order', eventGroup.languages.sort((a,b) => a.language < b.language ? -1 : 1))
    return <Box>
        <Alert sx={{my: 1}} severity={'info'}>
            <AlertTitle>This is an event group connected to <strong>Google Calendars</strong></AlertTitle>
            Events will be downloaded and mapped to your calendar configuration when preparing to print. You can
            configure the language specific sources below.
        </Alert>
        {eventGroup?.languages && eventGroup?.languages.length > 1 && <Alert sx={{mb: 1}} severity={'info'}>
            <AlertTitle>This calendar has <strong>more than one language</strong> enabled.</AlertTitle>
            Events from each language source will be matched by date and description.
            Please note {packageJson.officialName} only supports printing two languages at a time.
        </Alert>}
        {eventGroup?.languages && eventGroup.languages.sort((a,b) => a.language < b.language ? 1 : 0).map(lang => <LanguageGAPI lang={lang} key={lang.language} eventGroup={eventGroup}/>)}
    </Box>
}

export default GapiConfig