import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {CalendarMonth, Event} from "@mui/icons-material";

function Dashboard() {


    const navigate = useNavigate()

    return (
        <Box sx={{width: 1, display: 'flex', justifyContent: 'center', maxWidth: 'md', mt: 2}}>
            <Button
                variant={'outlined'}
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 1,
                    mr: 1

                }}
                onClick={() => navigate('/events')}
            >
                <Event sx={{m: 1}} fontSize={'large'}/>
                <Typography>Event Groups</Typography>
            </Button>
            <Button
                variant={'outlined'}
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 1,
                    ml: 1

                }}
                onClick={() => navigate('/calendar')}
            >
                <CalendarMonth sx={{m: 1}} fontSize={'large'}/>
                <Typography>Calendar Templates</Typography>
            </Button>
        </Box>
    )
}

export default Dashboard