import {collection, FirestoreError, getDocs, getFirestore} from "firebase/firestore";
import {useQuery} from "@tanstack/react-query";
import {app} from "../../auth/FirebaseConfig";

export function useGetOrganisations(userId: string | undefined){
    const db = getFirestore(app);
    return useQuery<any, FirestoreError>({
        queryKey: ["organisations"],
        // TODO: User Organisation Selection #9
        queryFn: () => getDocs(collection(db, "organisations")),
        enabled: !!userId,
        // onError: error => {
        //     console.error(error)
        //     enqueueSnackbar(error.message, {variant: 'error'})
        // }
    });
}