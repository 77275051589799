import {Box, Button, Icon, Popover, ToggleButton, Tooltip} from "@mui/material";
import React, {useState} from "react";
import {iconList} from "./IconList";
import {QuestionMark} from "@mui/icons-material";

function IconPicker({
                        icon,
                        setNewGroup,
                        disabled,
                        pad,
                        updateGroup
                    }: { icon: string | undefined, setNewGroup?: any, disabled: boolean, pad: number, updateGroup?: any }) {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const popperWidth = 400
    return <>
        <Tooltip title={'Click to change'}>
            <ToggleButton
                value={false}
                onClick={handleClick}
                sx={{
                    p: pad,
                    '& .MuiIcon-root': {
                        lineHeight: 0
                    }
                }}
                disabled={disabled}
            >
                {icon ? <Icon fontSize={'large'}>
                        <img
                            src={`https://firebasestorage.googleapis.com/v0/b/mycal-367400.appspot.com/o/public%2Ficons%2F${icon}.svg?alt=media`}
                            alt={icon}/>
                    </Icon> :
                    <QuestionMark fontSize={'large'}/>}
            </ToggleButton>
        </Tooltip>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box sx={{width: popperWidth, m: 1 / 2, display: 'flex-wrap'}}>
                {iconList.map(icon => {
                    return <Button
                        key={icon}
                        sx={{
                            m: 1 / 2,
                            p: 1,
                            width: (popperWidth / 5) - 10,
                            '& .MuiIcon-root': {
                                lineHeight: 0
                            }
                        }}
                        variant={'outlined'}
                        color={'inherit'}
                        onClick={() => {
                            if (setNewGroup) {
                                setNewGroup((g: any) => {
                                    return {...g, icon: icon}
                                })
                            } else if (updateGroup) {
                                updateGroup({icon: icon})
                            }
                            setAnchorEl(null)
                        }}
                    >
                        <Icon>
                            <img
                                src={`https://firebasestorage.googleapis.com/v0/b/mycal-367400.appspot.com/o/public%2Ficons%2F${icon}.svg?alt=media`}
                                alt={icon}/>
                        </Icon>
                    </Button>
                })}
            </Box>
        </Popover>
    </>
}

export default IconPicker