import {GridPreProcessEditCellProps, GridRenderEditCellParams} from "@mui/x-data-grid";
import {format, getDay, getYear, isValid} from "date-fns";
import {EventInterface} from "../../../firestore_interfaces/EventInterface";
import {GridBaseColDef} from "@mui/x-data-grid/internals";
import React from "react";
import {CustomDatePicker, DayEdit, EstEdit, MonthEdit} from "./EditComponents";
import {invalidDay, invalidEst, invalidMonth} from "./AddEventCalcs";

export interface eventCol extends GridBaseColDef {
    field: keyof EventInterface | 'age',
}

export const eventColumns: eventCol[] = [
    {
        field: '_id',
        headerName: 'ID'
    },
    {
        field: 'day',
        headerName: 'Day',
        flex: 1,
        editable: true,
        renderHeader: (params) => params.colDef.headerName + '*',
        renderEditCell: (params: GridRenderEditCellParams) => <DayEdit {...params}/>,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            return {...params.props, error: invalidDay({...params.row, day: params.props.value})};
        },
    },
    {
        field: 'month',
        headerName: 'Month',
        flex: 1,
        editable: true,
        renderHeader: (params) => params.colDef.headerName + '*',
        renderEditCell: (params: GridRenderEditCellParams) => <MonthEdit {...params}/>,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            return {...params.props, error: invalidMonth({...params.row, month: params.props.value})};
        },
        valueFormatter: params => {
            const date = new Date(getYear(new Date()), params.value, getDay(new Date()))
            if (isValid(date)) {
                return format(date, 'MMM')
            } else {
                return params.value
            }
        }
    },
    {
        field: 'year',
        headerName: 'Year',
        flex: 1,
        editable: true,
        renderHeader: (params) => params.colDef.headerName + '*',
    },
    {
        field: 'est',
        headerName: 'Year',
        flex: 1,
        editable: true,
        renderHeader: (params) => params.colDef.headerName,
        renderEditCell: (params: GridRenderEditCellParams) => <EstEdit {...params}/>,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
            console.log('est preProcessEditCellProps', params)
            return {...params.props, error: invalidEst({...params.row, est: params.props.value})};
        },
    },
    {
        field: 'age',
        headerName: 'Age / Est.',
        flex: 1.5,
        editable: false,
        valueFormatter: params => params.value === 'Unknown' ? '' : params.value
    },
    {
        field: 'date',
        headerName: 'Date',
        type: 'dateTime',
        editable: true,
        valueGetter: params => {
            if (params.value?.seconds) {
                return new Date(params.value.seconds * 1000)
            } else {
                return params.value
            }

        },
        valueFormatter: params => {
            if(params?.value){
                try {
                    return format(params.value, 'dd / MMM / yyyy')
                } catch (e) {
                    console.error(e)
                    return 'Error'
                }
            } else {
                return ''
            }
        },
        flex: 1.5,
        renderEditCell: (params: GridRenderEditCellParams) => <CustomDatePicker {...params}/>
    },

]
