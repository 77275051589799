import {useMutation} from "@tanstack/react-query";
import {
    AuthError,
    createUserWithEmailAndPassword,
    getAuth, sendPasswordResetEmail,
    signInWithEmailAndPassword,
    UserCredential
} from "firebase/auth";
import {useNavigate} from "react-router-dom";

export function useCreateUser(){

    const auth = getAuth()

    return useMutation<UserCredential, AuthError, any, any>({
        mutationFn: ({email, password}) => createUserWithEmailAndPassword(auth, email, password),
        onSuccess: (_data, {email, noOffers}) => {
            // TODO: make transaction
            // addUserRecord({email, noOffers})
        }
    })
}

export function useLoginUser(){

    const auth = getAuth()
    const navigate = useNavigate()

    return useMutation<UserCredential, AuthError, any, any>({
        mutationFn: ({email, password}) => signInWithEmailAndPassword(auth, email, password),
        onSuccess: () => navigate('/profile')
    })
}

export function useResetUserPassword(){

    const auth = getAuth()
    const navigate = useNavigate()

    return useMutation<any, AuthError, any, any>({
        mutationFn: ({email}) => sendPasswordResetEmail(auth, email),
        onSuccess: () => navigate('/login')
    })
}