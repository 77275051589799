import {useQueries, useQuery} from "@tanstack/react-query";
import {collection, FirestoreError, getDocs, getFirestore, query, Timestamp, where, or, Firestore} from "firebase/firestore";
import {app} from "../../auth/FirebaseConfig";
import {SelectedPeriodInterface} from "../../LocalInterfaces";
import {CategorySources} from "../../firestore_interfaces/CategoryInterface";
import {CombinedResultsInterface, firedocsResultToComboData} from "../../functions/CombineResults";
import {EventInterface} from "../../firestore_interfaces/EventInterface";

function buildGetEventsQuery(db: Firestore, group_id: string | undefined, period: SelectedPeriodInterface | 'future', source: CategorySources | undefined){

    // default
    let eventQuery = query(collection(db, "events", group_id!, 'event'))

    if (period === 'future') {
        eventQuery = query(
            collection(db, "events", group_id!, 'event'),
            or(
                where('date', '>=', Timestamp.fromDate(new Date())), //single events have date object
                where('est', "!=", false) //annual
                // FIXME: est isn't 'required' to be annual
            )
        )
    } else if (source) {
        // switch (source) {
        //     case "annual":
        //         // FIXME: est isn't 'required' to be annual
        //         eventQuery = query(
        //             collection(db, "events", group_id!, 'event'),
        //             where('est', '!=', false),
        //             where('month', '==', period.month)
        //         )
        //         break
        //     case "single":
        //         eventQuery = query(
        //             collection(db, "events", group_id!, 'event'),
        //             where('year', '==', period.year),
        //             where('month', '==', period.month)
        //         )
        //         break
        // }
    }

    return eventQuery
}

export function useGetEvents(group_id: string | undefined, period: SelectedPeriodInterface | 'future', source?: CategorySources) {

    const db = getFirestore(app)

    return useQuery<any, FirestoreError>({
        queryKey: ["events", group_id, 'event', period, source],
        queryFn: () => getDocs(buildGetEventsQuery(db, group_id, period, source)),
        enabled: !!group_id && !!source && source !== 'api',
        // onError: error => {
        //     console.error(error)
        //     enqueueSnackbar(error.message, {variant: 'error'})
        // }
    });
}

export function useGetArrayEvents(selectedEventsGroups: string[], period: SelectedPeriodInterface, source: CategorySources){

    const db = getFirestore(app);

    return useQueries<Array<any>, CombinedResultsInterface<EventInterface>>({
        queries: selectedEventsGroups.map((group_id) => {
            return {
                queryKey: ["events", group_id, 'event', period, source],
                queryFn: async () => {
                    const querySnapshot = await getDocs(buildGetEventsQuery(db, group_id, period, source))
                    return querySnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}));
                }
            }
        }),
        combine: results => firedocsResultToComboData(results)
    })
}