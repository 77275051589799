import {doc, FirestoreError, getDoc, getFirestore, serverTimestamp, updateDoc} from "firebase/firestore";
import {app} from "../../auth/FirebaseConfig";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

// const {mutate: addUserRecord} = useMutation<any, FirestoreError, any, () => void>({
//     mutationFn: async (user) => {
//         console.log(user)
//         const db = getFirestore(app);
//         const newUserRef = doc(collection(db, "users"));
//         await setDoc(newUserRef, {
//             ...user, _id: newUserRef.id, createdAt: serverTimestamp()
//         })
//         return {...user, _id: newUserRef.id}
//     }
// })

export function useGetUser(userId: string | undefined) {
    const db = getFirestore(app);
    return useQuery<any, FirestoreError>({
        queryKey: ["users", userId],
        queryFn: () => getDoc(doc(db, "users", userId!)),
        enabled: !!userId,
        // onError: error => {
        //     console.error(error)
        //     enqueueSnackbar(error.message, {variant: 'error'})
        // },
        select: data => data.data()
    });
}

export function useUpdateUser(uid: string) {
    const db = getFirestore(app);
    const queryClient = useQueryClient()
    return useMutation<any, FirestoreError, any, () => void>({
        mutationFn: async (calendar) => {
            const ref = doc(db, "users", uid)
            return updateDoc(ref, {...calendar, "updatedAt": serverTimestamp()})
        },
        onSuccess: async () => queryClient
            .invalidateQueries({queryKey: ['users', uid]}),
        onError: error => {
            console.error(error)
            // enqueueSnackbar(error.message, {variant: 'error'})
        },
    })
}
