import {Avatar, Box, Button, Checkbox, FormControlLabel, FormHelperText, TextField, Tooltip} from "@mui/material";
import {useAuthedUserContext} from "../../auth/AuthedRoute";
import packageJson from "../../../../package.json";
import AccountSection from "../../auth/AccountSection";
import {DateField} from "@mui/x-date-pickers";
import {useGoogleAuthContext} from "../../../auth/GoogleLogin";
import {Login, Logout} from "@mui/icons-material";
import {useGetUser} from "../../../api/hooks/dbUser";
import {useGoogleCalendarsList} from "../../../api/hooks/googleGet";

function UserProfile() {

    const user: any = useAuthedUserContext()
    const {googleUser, signOut, signIn} = useGoogleAuthContext()

    const {data: userData} = useGetUser(user?.uid)
    const {data: userCalendars} = useGoogleCalendarsList()

    return (<Box sx={{display: 'flex', width: 1, justifyContent: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', width: 400, m: 2}}>
            <AccountSection name={'User'}>
                <TextField
                    label='First Name'
                    value={userData?.firstName || ''}
                    margin={'normal'}
                    onChange={() => undefined} //TODO
                    fullWidth
                />
                <TextField
                    label='Last Name'
                    value={userData?.lastName || ''}
                    margin={'normal'}
                    onChange={() => undefined} //TODO
                    fullWidth
                />
                <TextField
                    label='Email'
                    value={user?.email || ''}
                    disabled
                    margin={'normal'}
                    fullWidth
                />
            </AccountSection>
            <AccountSection name={'Google User'} rightElement={googleUser ?
                <Tooltip title={'Logout'}>
                    <Button
                        onClick={async () => {
                            signOut()
                        }}
                        size={'large'}
                        variant={'outlined'}
                        color={'error'}
                    >
                        <Logout/>
                    </Button>
                </Tooltip> :
                <Tooltip title={'Login'}>
                    <Button
                        onClick={() => signIn()}
                        size={'large'}
                        variant={'outlined'}
                        color={'success'}
                    >
                        <Login/>
                    </Button>
                </Tooltip>}>
                <FormHelperText>{`Login to enable viewing your google calendars`}</FormHelperText>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Avatar
                        alt="GoogleUserProfileImage"
                        src={googleUser?.profileImg}
                        sx={{width: 50, height: 50}}
                    />
                    <TextField
                        label='Name'
                        value={googleUser?.name || ''}
                        margin={'normal'}
                        disabled
                        onChange={() => undefined} //TODO
                        fullWidth
                        sx={{mx: 1}}
                    />
                    <TextField
                        label='Calendars'
                        value={googleUser && userCalendars?.items.length ? userCalendars.items.length : ''}
                        margin={'normal'}
                        disabled
                        onChange={() => undefined} //TODO
                    />
                </Box>
            </AccountSection>
            <AccountSection name={'Account'}>
                <DateField
                    label='User Since'
                    value={userData?.createdAt.toDate()}
                    disabled
                    margin={'normal'}
                    fullWidth
                />
                <FormControlLabel
                    sx={{my: 1 / 2, width: 1}}
                    control={<Checkbox
                        sx={{py: 1 / 2}}
                        checked={userData?.noOffers}
                    />}
                    label={`Please do not email me ${packageJson.officialName} special offers`}
                />
                <TextField
                    label='Membership Plan'
                    value={'Free Tier'}
                    disabled
                    margin={'normal'}
                    onChange={() => undefined} //TODO
                    fullWidth
                />
            </AccountSection>
        </Box>
    </Box>)
}

export default UserProfile