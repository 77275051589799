import {EventInterface} from "../../firestore_interfaces/EventInterface";
import {GAPICalendarEvent} from "../../google_cal_interfaces/GAPICalendarEvent";

export function googleToFirebaseEvents(event: GAPICalendarEvent): Omit<EventInterface, 'createdAt'> {
    const date = event?.start?.dateTime ? new Date(event?.start?.dateTime) : new Date(event?.start?.date)
    return {
        label: {
            en: event?.summary
        },
        day: date.getDate(),
        month: date.getMonth(),
        date: date,
        _id: event?.id
    }
}