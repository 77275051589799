import {Alert, Box, Button, TextField, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import packageJson from '../../../package.json'
import {useFormik} from 'formik';
import * as yup from 'yup';
import {LoadingButton} from "@mui/lab";
import RenderFirebaseAuthError from "./FirebaseAuthError";
import {useLoginUser} from "../../api/hooks/firebaseUser";

function Login() {
    const navigate = useNavigate()
    const location = useLocation()

    const {
        mutate: login,
        isPending,
        error
    } = useLoginUser()

    const LoginSchema = yup.object({
        email: yup
            .string()
            .email('Please enter a valid email address.')
            .required('Email is required.'),
        password: yup
            .string()
            .min(8, 'Password should be of minimum 8 characters length.')
            .required('Password is required.'),
    });

    const formik = useFormik({
        initialValues: {
            email: location.state?.email as string,
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => login(values)
    });

    return (<Box sx={{display: 'flex', width: 1, justifyContent: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', width: 360, m: 10}}>
            <Typography
                sx={{textAlign: 'center', my: 3}}>{`${packageJson.officialName} ${packageJson.version}`}</Typography>

            <Typography variant={"h3"} sx={{mb: 2}}>Sign in</Typography>
            <form onSubmit={formik.handleSubmit}>
                {error && <Alert severity={"warning"} sx={{my: 1}}>
                    <RenderFirebaseAuthError error={error}/>
                </Alert>}
                <TextField
                    sx={{my: 1 / 2}}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                    sx={{my: 1 / 2}}
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && !!formik.errors.password}
                    helperText={formik.touched.password && formik.errors.password}
                />
                <LoadingButton
                    sx={{mt: 1, mb: 1 / 4}}
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={Object.keys(formik.errors).length > 0}
                    loading={isPending}
                >
                    Sign in
                </LoadingButton>
                <Box sx={{display: 'flex'}}>
                    <Button sx={{ml: 'auto'}}>
                        Need help?
                    </Button>
                </Box>
                <Typography sx={{mt: 2}}>{`New to ${packageJson.officialName}?`}</Typography>
                <Button
                    sx={{mt: 1, mb: 1 / 4}}
                    color="primary"
                    variant="outlined"
                    fullWidth
                    onClick={() => navigate('/signup/regform')}
                >
                    Sign up now
                </Button>
            </form>
        </Box>
    </Box>)
}

export default Login