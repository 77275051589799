import {AxiosResponse} from "axios/index";
import CalendarService from "../services/CalendarPDF";
import {useMutation} from "@tanstack/react-query";
import {CalendarInterface} from "../../firestore_interfaces/CalendarInterface";
import {EventInterface} from "../../firestore_interfaces/EventInterface";
import {SelectedPeriodInterface} from "../../LocalInterfaces";
import {CategoryInterface} from "../../firestore_interfaces/CategoryInterface";

interface pdfPayload{
    calendar: CalendarInterface
    events: EventInterface[]
    period: SelectedPeriodInterface
    groups: CategoryInterface[]
}
export function useGetPdfUrl(){
    return useMutation<any, AxiosResponse, pdfPayload, () => void>({
        mutationFn: async ({calendar, events, groups, period}) => {
            return CalendarService.getPdf({
                calendar: calendar,
                events: events,
                period: period,
                groups: groups
            }).then(data => window.URL.createObjectURL(data))
        },
        // onError: error => {
        //     console.error(error)
        //     enqueueSnackbar(error.statusText, {variant: 'error'})
        // },
    })
}