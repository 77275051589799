import {Alert, Box, TextField, Typography} from "@mui/material";
import packageJson from '../../../package.json'
import {useFormik} from 'formik';
import * as yup from 'yup';
import {LoadingButton} from "@mui/lab";
import {useResetUserPassword} from "../../api/hooks/firebaseUser";

function LoginHelp() {
    const {mutate: reset, isPending, error} = useResetUserPassword()

    const LoginHelpSchema = yup.object({
        email: yup
            .string()
            .email('Please enter a valid email address.')
            .required('Email is required.'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: LoginHelpSchema,
        onSubmit: (values) => reset(values)
    });

    return (<Box sx={{display: 'flex', width: 1, justifyContent: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', width: 360, m: 10}}>
            <Typography
                sx={{textAlign: 'center', my: 3}}>{`${packageJson.officialName} ${packageJson.version}`}</Typography>

            <Typography variant={"h3"} sx={{mb: 2}}>Forgot password</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Typography variant={'body1'}>We will send you an email with instructions on how to reset your
                    password.</Typography>
                {error && <Alert severity={"error"} sx={{my: 1}}>
                    Sorry, but something went wrong. Please try again later.
                </Alert>}
                <TextField
                    sx={{mt: 1}}
                    fullWidth
                    id="email"
                    name="email"
                    placeholder={'name@example.com'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <LoadingButton
                    sx={{mt: 1, mb: 1 / 4}}
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={Object.keys(formik.errors).length > 0}
                    loading={isPending}
                >
                    Email me
                </LoadingButton>
            </form>
        </Box>
    </Box>)
}

export default LoginHelp