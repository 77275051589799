import {UseQueryResult} from "@tanstack/react-query";
import {GAPICalendarEvent} from "../google_cal_interfaces/GAPICalendarEvent";
import {googleConvert} from "./GoogleConvert";
import {EventInterface} from "../firestore_interfaces/EventInterface";

export interface CombinedResultsInterface<T>{
    data: T[],
    pending: boolean,
    error: boolean
}
export function firedocsResultToComboData(results: UseQueryResult<any, Error>[]) {
    const data: any[] = []
    results.forEach((result: any)  => data.push(result.data?.[0]))
    return ({
        data: data.filter(d => d),
        pending: results.some(result => result.isPending),
        error: results.some(result => result.isError)
    })
}

export function googleEventsToComboData(results: UseQueryResult<any, any>[]){
    let data: GAPICalendarEvent[] = []
    results.forEach((result: any) => {
        if (result.data?.items) {
            data = data.concat(result.data.items.map((i: any) => {return {...i, groupId: result.data.groupId}}))
        }
    })
    return ({
        data: data.map(e => googleConvert(e) as EventInterface),
        pending: results.some(result => result.isPending),
        error: results.filter(result => result.error).map(result => result.error?.data)
    })
}