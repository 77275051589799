import {
    DataGrid,
    GridActionsCellItem,
    GridEventListener,
    GridPreProcessEditCellProps,
    GridRenderEditCellParams,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    MuiEvent
} from "@mui/x-data-grid";
import {ageCalc} from "../AddEventCalcs";
import {eventColumns} from "../EventColumns";
import EditToolbar from "../EditToolbar";
import {Box} from "@mui/material";
import React, {useCallback, useState} from "react";
import {CategoryInterface} from "../../../../firestore_interfaces/CategoryInterface";
import {GridBaseColDef} from "@mui/x-data-grid/internals";
import {LabelEdit} from "../EditComponents";
// @ts-ignore
import {v4 as uuid} from 'uuid';
import {Cancel, Delete, Edit, Save} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import AddEventModal from "../AddEventModal";
import {useGetEvents} from "../../../../api/hooks/dbEventList";
import {useDeleteEvent, useUpdateEvent} from "../../../../api/hooks/dbEvent";

function StoredConfig({category}: { category: CategoryInterface }) {

    const {events_id} = useParams();

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [eventData, setEventData] = useState<any[]>([])

    const annual = category?.annual
    const iconOnly = !category?.label

    const labelCols: GridBaseColDef[] = category?.languages ? category.languages.map(lang => {
        const l = lang?.language ? lang.language : uuid()
        return {
            field: `label.${l}`,
            headerName: `Label (${l.toUpperCase()})`,
            flex: 3,
            editable: true,
            valueGetter: ({row, value}) => {
                return value === undefined ? row.label?.[l] : value
            },
            renderEditCell: (params: GridRenderEditCellParams) => <LabelEdit {...params}/>,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                return {...params.props, error: params.props.value.length < 1}
            }
        }
    }) : []

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = async (id: GridRowId) => {
        await deleteEvent(id)
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
    };

    const processRowUpdate = async (newRow: GridRowModel, oldRow: any) => {
        const dbUpdate: any = {}
        const newLabel: any = {}
        Object.entries(newRow).forEach(([key, val]) => {
            if (key.includes('label')) {
                const lang = /^label.([A-z]+)$/g.exec(key)?.[1]
                if (lang) {
                    newLabel[lang] = val
                }
            } else if (val !== undefined && key !== 'id' && key !== 'age') {
                dbUpdate[key] = val
            }
        })
        dbUpdate.label = newLabel
        setEventData(e => e.map((row) => (row.id === newRow.id ? newRow : row)));
        await updateEvent(dbUpdate)
        return newRow;
    };

    const handleProcessRowUpdateError = useCallback((error: Error) => {
        console.log(error)
    }, []);

    const {mutate: updateEvent, isPending: updatingEvent} = useUpdateEvent(events_id!)
    const {mutate: deleteEvent, isPending: deletingEvent} = useDeleteEvent(events_id!)
    const {data: futureEvents} = useGetEvents(events_id, 'future')


    // useEffect(() => {
    //     if (futureEvents) {
    //         const temp: any[] = []
    //         futureEvents.docs.forEach((doc: any) => {
    //             const e = doc.data()
    //             temp.push({...e, id: e._id})
    //         })
    //         setEventData(temp)
    //      }
    // }, [futureEvents, estEvents])

    const actions = {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({id, row}: any) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<Save/>}
                        label="Save"
                        onClick={handleSaveClick(id)}
                        color="inherit"
                        disabled={updatingEvent}
                    />,
                    <GridActionsCellItem
                        icon={<Cancel/>}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                    />,
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<Edit/>}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id)}
                    color="inherit"
                />,
                <GridActionsCellItem
                    icon={<Delete/>}
                    label="Delete"
                    onClick={async () => handleDeleteClick(id)}
                    color="inherit"
                    disabled={deletingEvent}
                />,
            ];
        }
    }

    const [openAdd, setOpenAdd] = useState(false)

    return (
        <Box sx={{
            height: '60vh',
            pt: 2,
            '& .MuiInputBase-input': {
                p: 1 / 2,
            }
        }}>
            <DataGrid
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                rows={eventData.map(e => {
                    return {...e, age: ageCalc(e)}
                })}
                columns={[...labelCols, ...eventColumns, actions]}
                hideFooter
                disableColumnMenu
                slots={{
                    toolbar: EditToolbar
                }}
                slotProps={{
                    toolbar: {setOpenAdd: setOpenAdd, eventGroup: category}
                }}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                // checkboxSelection
                // disableSelectionOnClick
                columnVisibilityModel={{
                    _id: false,
                    id: false,
                    year: false,
                    est: !!annual,
                    age: !!annual,
                    name: !iconOnly,
                    day: !!annual,
                    month: !!annual,
                    date: !annual
                }}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'date', sort: 'asc'}]
                    }
                }}
                rowHeight={40}
            />
            <AddEventModal open={openAdd} setOpen={setOpenAdd} group={category}/>
        </Box>
    )
}

export default StoredConfig