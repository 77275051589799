import {
    Box,
    Checkbox,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import {format} from "date-fns";
import {AccountCircle, CloudCircle, ExpandLess, ExpandMore} from "@mui/icons-material";
import {useAuthedUserContext} from "../../../auth/AuthedRoute";
import React, {useEffect, useState} from "react";
import {OrganisationInterface, UserInterface} from "../../../../firestore_interfaces/EventAdminInterfaces";
import EventsInGroup from "./EventsInGroup";
import {CalendarInterface} from "../../../../firestore_interfaces/CalendarInterface";
import {useGetUser} from "../../../../api/hooks/dbUser";
import {useGetOrganisations} from "../../../../api/hooks/dbOrganisations";

function SelectEvents({
                          selectedPeriod,
                          selectedEventsGroups,
                          setSelectedEventGroups,
                          calendar
                      }: {
    selectedPeriod: any,
    selectedEventsGroups: string[],
    setSelectedEventGroups: any,
    calendar: CalendarInterface
}) {

    // FIXME: Cant sync state in onSuccess
    // useQuery<any, FirestoreError>({
    //     queryKey: ["calendars", calendar_id],
    //     queryFn: () => getDoc(doc(db, "calendars", calendar_id!)),
    //     enabled: !!calendar_id,
    //     select: data => data?.data()
    //     // onError: error => {
    //     //     console.error(error)
    //     //     enqueueSnackbar(error.message, {variant: 'error'})
    //     // },
    //     // onSuccess: data => data?.events && setSelectedEventGroups(data?.events)
    // })

    const user: any = useAuthedUserContext()
    const userId = user?.uid
    // FIXME: Cant sync state in onSuccess
    // onSuccess: data => {
    //     setSelectedGroup(Object.keys(data.events)[0])
    // }
    const {data: userData} = useGetUser(userId)

    const userTyped: UserInterface | undefined = userData

    const {data: orgs} = useGetOrganisations(userId)

    const [orgData, setOrgData] = useState<OrganisationInterface[]>([])
    const [selectedGroup, setSelectedGroup] = useState<undefined | string>(undefined)
    const [openGroup, setOpenGroup] = useState<{ [key: string]: boolean }>({
        user: true
    });


    useEffect(() => {
        if (orgs) {
            const temp: any[] = []
            orgs.docs.forEach((doc: any) => {
                const e = doc.data()
                temp.push({...e, id: e._id})
            })
            setOrgData(temp)
        }
    }, [orgs])

    function handleToggle(value: string) {
        setSelectedEventGroups((c: any) => {
            if (c.includes(value)) {
                return c.filter((e: any) => e !== value)
            } else {
                return [...c, value]
            }
        })
        setSelectedGroup(value)
    }

    return (
        <Box sx={{width: 1, display: 'flex', maxWidth: 'lg', flexDirection: {sm: 'column', lg: 'row'}}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2, width: 1, flex: 2}}>
                <Typography
                    variant={'h5'}
                    sx={{my: 1}}
                >
                    Groups
                </Typography>
                <Paper sx={{width: 1}} variant={'outlined'}>
                    <List
                        sx={{
                            width: 1,
                            maxHeight: 400,
                            position: 'relative',
                            overflow: 'auto',
                            py: 0,
                        }}
                    >
                        <ListItemButton onClick={() => setOpenGroup(o => {
                            return {...o, user: !o.user}
                        })}>
                            <ListItemIcon>
                                <AccountCircle/>
                            </ListItemIcon>
                            <ListItemText primary="My Event Groups"/>
                            {openGroup.user ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Divider/>
                        <Collapse in={openGroup.user} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {userTyped && Object.entries(userTyped.events).map(([key, val], index): any => {
                                        return (
                                            <ListItem
                                                key={key}
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        onChange={() => handleToggle(key)}
                                                        checked={selectedEventsGroups.includes(key)}
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton
                                                    selected={key === selectedGroup}
                                                    sx={{pl: 2}}
                                                    onClick={() => setSelectedGroup(key)}
                                                >
                                                    <ListItemText primary={val}/>
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    }
                                )}
                            </List>
                        </Collapse>
                        {orgData.map(org => {
                            return <Box key={org._id}>
                                <Divider/>
                                <ListItemButton
                                    onClick={() => setOpenGroup(o => {
                                        return {...o, [org._id]: !o?.[org._id]}
                                    })}
                                >
                                    <ListItemIcon>
                                        <CloudCircle/>
                                    </ListItemIcon>
                                    <ListItemText primary={org.name}/>
                                    {openGroup?.[org._id] ? <ExpandLess/> : <ExpandMore/>}
                                </ListItemButton>
                                <Divider/>
                                <Collapse in={openGroup?.[org._id]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {Object.entries(org.events).map(([key, val], index): any => {
                                            return (
                                                <ListItem
                                                    key={key}
                                                    secondaryAction={
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={() => handleToggle(key)}
                                                            checked={selectedEventsGroups.includes(key)}
                                                        />
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton
                                                        sx={{pl: 2}}
                                                        selected={key === selectedGroup}
                                                        onClick={() => setSelectedGroup(key)}
                                                    >
                                                        <ListItemText primary={val}/>
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Collapse>
                            </Box>
                        })}
                    </List>
                </Paper>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2, width: 1, flex: 5}}>
                <Typography
                    variant={'h5'}
                    sx={{my: 1}}
                >
                    {`Events in ${format(new Date(selectedPeriod.year, selectedPeriod.month - 1, 1), 'MMMM, yyyy')}`}
                </Typography>
                <Paper sx={{width: 1, height: 1}} variant={'outlined'}>
                    <EventsInGroup
                        group_id={selectedGroup}
                        period={selectedPeriod}
                        calendar={calendar}
                    />
                </Paper>
            </Box>
        </Box>
    )
}

export default SelectEvents