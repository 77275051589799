import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DeleteForever} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import IconPicker from "../IconPicker";
import {CategoryInterface} from "../../../../firestore_interfaces/CategoryInterface";
import GapiConfig from "./GapiConfig";
import StoredConfig from "./StoredConfig";
import {useDeleteEventGroup, useUpdateEventGroup} from "../../../../api/hooks/dbEventsGroup";

function EventsConfig({eventGroup: category}: { eventGroup: CategoryInterface }) {

    const {events_id} = useParams();
    const navigate = useNavigate()


    const {mutate: deleteEventGroup, isPending: deletingEventGroup} = useDeleteEventGroup(events_id)
    const {mutate: updateEventGroup} = useUpdateEventGroup(events_id)

    // TODO: make transaction
    // updateEventGroup.onSuccess: data => updateGroupInUser(data),

    // const user: any = useUserContext()
    // const userId = user?.uid

    // const {mutate: updateGroupInUser} = useMutation<any, FirestoreError, any, () => void>({
    //     mutationFn: async (calendar) => {
    //         const ref = doc(db, "users", userId)
    //         const key = `events.${calendar._id}`
    //         return updateDoc(ref, {
    //                 "updatedAt": serverTimestamp(),
    //                 [key]: calendar.name
    //             }
    //         )
    //     },
    //     onSuccess: async () => await queryClient.invalidateQueries({queryKey: ['users', userId]}),
    //     // onError: error => {
    //     //     console.error(error)
    //     //     enqueueSnackbar(error.message, {variant: 'error'})
    //     // },
    // })

    // const {mutate: removeGroupFromUser} = useMutation<any, FirestoreError, any, () => void>({
    //     mutationFn: async (id) => {
    //         const ref = doc(db, "users", userId)
    //         const key = `events.${id}`
    //         return updateDoc(ref, {
    //                 "updatedAt": serverTimestamp(),
    //                 [key]: deleteField()
    //             }
    //         )
    //     },
    //     onSuccess: async () => await queryClient.invalidateQueries({queryKey: ['users', userId]}),
    //     // onError: error => {
    //     //     console.error(error)
    //     //     enqueueSnackbar(error.message, {variant: 'error'})
    //     // },
    // })


    const [openDelete, setOpenDelete] = useState(false)

    const deleteInProgress = deletingEventGroup

    const [localName, setLocalName] = useState('')

    return <Box sx={{width: 1}}>
        <Box sx={{display: 'flex'}}>
            {category?.icon &&
                <IconPicker icon={category?.iconImg} updateGroup={updateEventGroup} disabled={false} pad={1}/>}
            <TextField
                sx={{mr: 1, ml: category?.icon ? 1 : 0}}
                fullWidth
                label={'Event Group Name'}
                value={localName || category?.name}
                required
                onChange={e => {
                    if (e.target.value.length > 0) {
                        setLocalName(e.target.value)
                    }
                }}
                onBlur={e => {
                    if (e.target.value.length > 0 && category?.name !== e.target.value) {
                        updateEventGroup({name: e.target.value})
                    }
                }}
            />
            <Button
                variant={'outlined'}
                onClick={() => setOpenDelete(true)}
                color={'error'}
            >
                <DeleteForever/>
            </Button>
        </Box>
        {category?.source === 'api' ?
            <GapiConfig eventGroup={category}/> :
            <StoredConfig category={category}/>
        }
        <Dialog open={openDelete}>
            <DialogTitle>Are you sure you want to <strong>delete</strong> this event group?</DialogTitle>
            <DialogContent>
                {deleteInProgress &&
                    <Box sx={{width: 1, p: 1, display: 'flex', justifyContent: 'center'}}><CircularProgress/></Box>}
                <DialogContentText sx={{display: 'flex', flexDirection: 'column'}}>
                    <span>All events in this group will also be deleted.</span>
                    <span>Externally referenced calendars will not be affected.</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDelete(false)}>No</Button>
                <Button
                    onClick={() => {
                        deleteEventGroup(undefined, {
                            onSuccess: () => {
                                setOpenDelete(false)
                                navigate('../')
                            }
                        })
                    }}
                    autoFocus
                    color={'error'}
                >
                    Yes, Delete
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
}

export default EventsConfig